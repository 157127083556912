import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/keyMetricsTile";
import { useAppSelector } from "../../../../store";
import "./KpiTiles.scss";
import { KeyMetricsData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { KPI } from "../../../enums/kpis";
import { formatToIDStr } from "../../../util/format";
import { IconArrowDropdown } from "../../../icons/IconArrowDropdown/IconArrowDropdown";
import { LeafIconButton } from "../../../leaf/LeafIconButton/LeafIconButton";
import { IconArrowUp } from "../../../icons/IconArrowUp/IconArrowUp";
import { storedLocale } from "../../../../components/impersonation/util";
import { getCountryNameFromCode } from "../../../util/formatCountryName";

type Props = {
  keyMetricsData: KeyMetricsData;
  kpis: string[];
  isLoading: boolean;
  showPVBreakdown: boolean;
};

export default function KpiTiles(props: Props) {
  const { t } = useTranslation(["keyMetricsTile"]);
  const { isoCountryCode } = useAppSelector((state) => state.boot.user);
  const [openTiles, setOpenTiles] = useState<Set<string>>(new Set());
  const locale = storedLocale();

  const { keyMetricsData, kpis, isLoading, showPVBreakdown } = props;

  const toggleBreakdown = (kpi: string) => {
    setOpenTiles((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(kpi)) {
        newSet.delete(kpi);
      } else {
        newSet.add(kpi);
      }
      return newSet;
    });
  };

  const getKpiLabel = (kpi: string, kpiValue: string | number) => {
    let kpiLabel;
    switch (kpi) {
      case KPI.SP_MONTHS:
        kpiLabel = Number(kpiValue) === 1 ? "spMonthsLabelSingular" : "spMonthsLabelPlural";
        break;
      default:
        kpiLabel = "";
    }

    return kpiLabel !== "" ? `${t(kpiLabel, defaultContent[kpiLabel], { value: kpiValue })}` : `${kpiValue}`;
  };

  const getTileData = (kpi: string): string => {
    const value = Object.prototype.hasOwnProperty.call(keyMetricsData.KeyMetrics, kpi)
      ? keyMetricsData.KeyMetrics[kpi]
      : "--";
    if (value === "") return "--";
    return getKpiLabel(kpi, value);
  };

  const showKpi = (kpi: string, showPVBreakdown: boolean) => {
    if (!keyMetricsData?.VolumeMetrics?.[kpi]?.VolumeByCountry || !showPVBreakdown) {
      return false;
    } else if (Object.keys(keyMetricsData.VolumeMetrics[kpi].VolumeByCountry).length <= 1) {
      return false;
    }

    return true;
  };

  const renderCountries = (canOpen: boolean, kpi: string, isBreakdownOpen: boolean) => {
    if (!canOpen || !isBreakdownOpen) {
      return <></>;
    }

    const countries = Object.keys(keyMetricsData.VolumeMetrics[kpi].VolumeByCountry);

    return (
      <div className="KeyMetrics__kpi-open">
        {countries.map((country) => (
          <Trans>
            <div key={country} className="KeyMetrics__kpi-open--country">
              <span>- {getCountryNameFromCode(country, locale)}</span>
              <span>{keyMetricsData.VolumeMetrics[kpi].VolumeByCountry[country]}</span>
            </div>
          </Trans>
        ))}
      </div>
    );
  };

  return (
    <section className="KeyMetrics__container">
      {kpis.map((kpi) => {
        const leafTileIDStr = formatToIDStr(`${kpi}-kpi-tile`);
        const isBreakdownOpen = openTiles.has(kpi);
        const canOpen = showKpi(kpi, showPVBreakdown);

        return (
          <LeafTile
            key={kpi}
            isLoading={isLoading}
            id={leafTileIDStr}
            className={isBreakdownOpen ? "is-open" : "KeyMetrics__closed"}
          >
            <div className="KeyMetrics__header">
              <p>{`${t(`${kpi}`, defaultContent[`${kpi}`])}`}</p>
              {canOpen && (
                <LeafIconButton
                  onClick={() => toggleBreakdown(kpi)}
                  Icon={
                    !isBreakdownOpen ? (
                      <IconArrowDropdown backgroundClass="black" size="lg" />
                    ) : (
                      <IconArrowUp backgroundClass="blue" size="lg" />
                    )
                  }
                />
              )}
            </div>
            <p className="KeyMetrics__kpi-data">{getTileData(kpi)}</p>
            {renderCountries(canOpen, kpi, isBreakdownOpen)}
          </LeafTile>
        );
      })}
    </section>
  );
}
