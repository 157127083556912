import { Trans, useTranslation } from "react-i18next";
import LeafPanel, { ModalProps } from "../../../../common/leaf/LeafPanel/LeafPanel";
import defaultContent from "../../../../content/bronzeIncentives";
import { LeafBadgeGrid as BadgeGrid, BonusHist } from "../../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import "./EligibleModal.scss";
import { getPerformanceYearShort, getCurrentPeriod } from "../../../../common/util/period";
import { useAppSelector } from "../../../../store";
import { AwardCap } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type EligibleProps = {
  bonusHistory: BonusHist[];
  awardCap: AwardCap;
  selectedPeriod: string;
} & ModalProps;

const EligibilityModal = ({ show, onCloseClick, title, bonusHistory, awardCap, selectedPeriod }: EligibleProps) => {
  const selectedPeriodPY = getPerformanceYearShort(selectedPeriod);
  const currentPY = getPerformanceYearShort(getCurrentPeriod());
  const { configuration } = useAppSelector((state) => state.boot);
  const show18MonthWindow =
    configuration.pathToBronze?.extendedWindowStart &&
    Number(selectedPeriod) >= configuration.pathToBronze.extendedWindowStart;

  const getModalHeader = () => {
    // Completed year (i.e selected period is not the current performance year)
    if (selectedPeriodPY !== currentPY) {
      return t(
        "newAboActivation.eligibleModal.closedYearHeader",
        defaultContent["newAboActivation"]["eligibleModal"]["closedYearHeader"],
        {
          YY: currentPY,
        },
      );
    }

    // ABO has earned the PTB incentive 1 - 5 times (in window)
    if (awardCap.actual > 0 && awardCap.actual < awardCap.target) {
      if (show18MonthWindow) {
        return t(
          "newAboActivation.eligibleModal.extended.earningHeader",
          defaultContent["newAboActivation"]["eligibleModal"]["extended"]["earningHeader"],
          {
            x: awardCap.actual,
          },
        );
      } else {
        return t(
          "newAboActivation.eligibleModal.earningHeader",
          defaultContent["newAboActivation"]["eligibleModal"]["earningHeader"],
          {
            x: awardCap.actual,
            y: selectedPeriodPY,
          },
        );
      }
    }

    // eligiblity met (graduated)
    if (awardCap.actual >= awardCap.target || awardCap.remaining === 0) {
      return t(
        "newAboActivation.eligibleModal.metHeader",
        defaultContent["newAboActivation"]["eligibleModal"]["metHeader"],
      );
    }

    // ABO has not yet earned the PTB incentive
    return t(
      "newAboActivation.eligibleModal.notEarningHeader",
      defaultContent["newAboActivation"]["eligibleModal"]["notEarningHeader"],
    );
  };

  const { t } = useTranslation(["bronzeIncentives"]);

  return (
    <LeafPanel id={"ptb-eligiblity-modal"} {...{ show, onCloseClick, title }}>
      <div className="eligible__card">
        <p className="eligible__card-title" data-testid="title">
          <Trans>{getModalHeader()}</Trans>
        </p>
        <p className="eligible__card-description">
          <Trans>
            {show18MonthWindow
              ? t(
                  "newAboActivation.eligibleModal.extended.eligibleDescription",
                  defaultContent["newAboActivation"]["eligibleModal"]["extended"]["eligibleDescription"],
                  {
                    max: awardCap.target,
                  },
                )
              : t(
                  "newAboActivation.eligibleModal.eligibleDescription",
                  defaultContent["newAboActivation"]["eligibleModal"]["eligibleDescription"],
                  {
                    max: awardCap.target,
                  },
                )}
          </Trans>
        </p>
        <BadgeGrid history={bonusHistory} />
      </div>
      <h1 className="eligible__description">
        <Trans>
          {t(
            "newAboActivation.eligibleModal.whoIsEligibleHeader",
            defaultContent["newAboActivation"]["eligibleModal"]["whoIsEligibleHeader"],
          )}
        </Trans>
      </h1>
      <div className="eligible__card">
        <Trans>
          {t(
            "newAboActivation.eligibleModal.whoIsEligibleDescription",
            defaultContent["newAboActivation"]["eligibleModal"]["whoIsEligibleDescription"],
          )}
        </Trans>
      </div>
    </LeafPanel>
  );
};

export default EligibilityModal;
