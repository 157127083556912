import { useState } from "react";
import { Link } from "react-router-dom";
import { track } from "@amwaycommon/acc-mixins/analytics";
import { IconSubsection } from "../../../icons/IconSubsection/IconSubsection";
import TEALIUM from "../../../enums/tealium-enums";
import { IconPlainTable } from "../../../icons/IconPlainTable/IconPlainTable";
import { IconCarrot } from "../../../icons/IconCarrot/IconCarrot";

type Props = {
  id: string;
  givenClass?: string;
  title: string;
  link?: string;
  tag: string;
  amount: string;
  subsection?: boolean;
  modalFn?: Function;
};

export const BonusBreakdownTileLine = ({
  id,
  givenClass = "",
  title,
  link = "",
  tag,
  amount,
  subsection = false,
  modalFn,
}: Props) => {
  const trackAnnualBonusBreakdownClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      tag,
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "annual_bonus_breakdown",
    );
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div className={givenClass + " Bbt__row"} key={id}>
      <div className={modalFn ? "Bbt__row__subsection" : ""}>
        {!modalFn && subsection ? <IconSubsection /> : <></>}
        {modalFn ? (
          <button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => modalFn()}
            className="Bbt__row__btn"
          >
            <IconPlainTable color={isHovered ? "purple" : "blue"} />
            {title}
            <span>
              <IconCarrot orientation="right" color={isHovered ? "purple" : "blue"} />
            </span>
          </button>
        ) : link !== "" ? (
          <Link to={link} onClick={trackAnnualBonusBreakdownClick}>
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <div className={modalFn ? "Bbt__row__subsection-amout" : ""}>
        {amount} {modalFn ? <IconSubsection /> : <></>}
      </div>
    </div>
  );
};
