import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeIncentives";
import { LeafModal } from "../../../../common/leaf/LeafModal/LeafModal";
import { Schedule } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BronzeConsistencyPaymentDetails.scss";

type Props = {
  module: string;
  showModal: boolean;
  onModalClose: Function;
  schedules: Schedule[];
  targetRequired: number;
  met: boolean;
};

const BronzeConsistencyPaymentDetails = (props: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { module, showModal, onModalClose, schedules, targetRequired, met } = props;

  const children = (
    <div className="bronze-consistency-payment-details__content">
      <h1 className="bronze-consistency-payment-details__header">
        <p>{`${t(`${module}Label`, defaultContent[`${module}Label`])}`}</p>
        <p>{`${t(
          "bronzeConsistencyPaymentDetails.subTitle",
          defaultContent["bronzeConsistencyPaymentDetails"]["subTitle"],
        )}`}</p>
      </h1>
      <div className="bronze-consistency-payment-details__schedule-list">
        {schedules.map(({ target, amount }) => (
          <div
            key={target}
            className={`bronze-consistency-payment-details__schedule bronze-consistency-payment-details__schedule${
              target === targetRequired && met === true ? "--met" : ""
            }`}
          >
            <div className="bronze-consistency-payment-details__target">{`${t(
              "bronzeConsistencyPaymentDetails.paymentEarned",
              defaultContent["bronzeConsistencyPaymentDetails"]["paymentEarned"],
              {
                target: target,
              },
            )}`}</div>
            <div className="bronze-consistency-payment-details__amount">{`${t(
              "bronzeConsistencyPaymentDetails.additionalBonus",
              defaultContent["bronzeConsistencyPaymentDetails"]["additionalBonus"],
              {
                bonus: amount,
              },
            )}`}</div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="bronze-consistency-payment-details">
      <LeafModal open={showModal} onCloseCallbackFn={onModalClose}>
        {children}
      </LeafModal>
    </div>
  );
};
export default BronzeConsistencyPaymentDetails;
