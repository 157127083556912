import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import "./PerfPlusElite.scss";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import defaultContent from "../../content/ppe";
import { ROUTE_LABELS } from "../../common/enums/routing-enums";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { getPerformanceIncentive } from "../../routing/MenuConfig/leaderGrowthDropdown";
import { IncentiveCalculation } from "../../common/components/IncentiveCalculation/IncentiveCalculation";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../common/components/ErrorTile/ErrorTile";
import { getFullMonth } from "../../common/util/period";
import { storedLocale } from "../impersonation/util";
import { IconTable } from "../../common/icons/IconTable/IconTable";
import { LeafIconButton } from "../../common/leaf/LeafIconButton/LeafIconButton";
import { IconMultiply } from "../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../common/icons/IconEquals/IconEquals";
import TEALIUM from "../../common/enums/tealium-enums";
import { RubyPerformanceTile } from "./RubyPerformanceTile/RubyPerformanceTile";
import { FetchPPEOverviewData } from "./PerfPlusEliteDataLayer";
import { PpeBannerTile } from "./PpeBannerTile/PpeBannerTile";
import { MultiplierMatrix } from "./MultiplierMatrix/MultiplierMatrix";
import { PPETrackProgressTile } from "./TrackProgressTile/PPETrackProgressTile";

const getPageTitle = (titleKey: string, translate: TFunction) => {
  const ppeKeys: string[] = [ROUTE_LABELS.PPE, ROUTE_LABELS.PERFORMANCE_PLUS, ROUTE_LABELS.PERFORMANCE_ELITE];
  const ppeTitle = ppeKeys.includes(titleKey)
    ? translate(`pageTitle.${titleKey}`, String(defaultContent["pageTitle"][titleKey]))
    : translate(`${titleKey}`);

  return ppeTitle;
};

const getIncentiveCalcMultiplierContent = (titleKey: string, translate: TFunction) => {
  if (titleKey === ROUTE_LABELS.PPE) {
    return translate(
      `incentiveCalc.incentive.multiplierPerfElite`,
      String(defaultContent.incentiveCalc.incentive["multiplierPerfElite"]),
    );
  }

  return translate(
    `incentiveCalc.incentive.multiplierPerfPlus`,
    String(defaultContent.incentiveCalc.incentive["multiplierPerfPlus"]),
  );
};

export default function PerformancePlusElite() {
  const { t } = useTranslation(["ppe"]);
  const { shellConfig } = useAppSelector(({ boot }) => boot);
  const dispatch = useAppDispatch();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const locale = storedLocale();
  const { search, state } = useLocation();
  const { navigation } = useAppSelector((state) => state.boot.configuration);
  const queryParams = new URLSearchParams(search);
  const showOldPpe = queryParams.get("showOldPpe") ?? navigation.showOldPpe;

  const [showMultiplierModal, setShowMultiplier] = useState(false);
  const [titleKey, setTitleKey] = useState<string>(state?.titleKey || ROUTE_LABELS.PPE);
  const pageTitle = getPageTitle(titleKey, t);

  const {
    ppeOverviewData,
    isLoading,
    errorStatus,
    refetch: refetchPPEOverviewData,
  } = FetchPPEOverviewData(selectedPeriod);

  const { IncentiveCalculation: IncentiveCalulationData, Requirements } = ppeOverviewData;

  const { PPERubyRequirements = [] } = Requirements;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      titleKey === "performancePlus" ? "performance_plus" : "performance_plus_and_elite",
      titleKey === "performancePlus" ? "performance_plus" : "performance_plus_and_elite",
      `${selectedPeriod}`,
      "",
    );
  }, [selectedPeriod, titleKey]);

  useEffect(() => {
    if (shellConfig.eligibleIncentives !== undefined) {
      const { performanceLabel } = getPerformanceIncentive(shellConfig.eligibleIncentives);
      setTitleKey(performanceLabel);
    }
  }, [shellConfig]);

  const openMultiplierModal = () => {
    setShowMultiplier(true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      titleKey === "performancePlus" ? TEALIUM.CATEGORY.PERFORMANCE_PLUS : TEALIUM.CATEGORY.PERFORMANCE_PLUS_ELITE,
      titleKey === "performancePlus" ? "performance_plus_incentive_calc" : "performance_plus_and_elite_incentive_calc",
      titleKey === "performancePlus" ? "performance_plus_multiplier" : "performance_plus_and_elite_multiplier",
    );
  };

  const closeMultiplierModal = () => setShowMultiplier(false);

  return (
    <main className="Ppe">
      <PageTitleSection title={pageTitle} />
      {errorStatus ? (
        <LeafTile hideBorder={true}>
          <ErrorTile
            clickFunction={refetchPPEOverviewData}
            errorStatus={errorStatus}
            isLoading={isLoading}
            tealiumTrackId="Performance_Plus_Elite_Overview"
          />
        </LeafTile>
      ) : (
        <div className="Ppe__container-split">
          <div className="Ppe__section-padding Ppe__container-row">
            <section className="Ppe__banner">
              <PpeBannerTile
                incomeData={ppeOverviewData.IncomeData}
                hideRequirements={ppeOverviewData.HideRequirements}
                selectedPeriod={selectedPeriod}
                isLoading={isLoading}
                titleKey={titleKey}
              />
            </section>
            {!ppeOverviewData.HideRequirements ? (
              <section className="Ppe__tracking">
                <PPETrackProgressTile
                  isLoading={isLoading}
                  requirements={ppeOverviewData.Requirements}
                  isPerfElite={titleKey === ROUTE_LABELS.PPE || titleKey === ROUTE_LABELS.PERFORMANCE_ELITE}
                />
              </section>
            ) : (
              <></>
            )}
          </div>
          {!ppeOverviewData.HideRequirements ? (
            <>
              <div className="Ppe__section-padding Ppe__calculation">
                <div>
                  <h2>{t("incentiveCalc.sectionHeader", String(defaultContent.incentiveCalc.sectionHeader))}</h2>
                </div>
                <div className="Ppe__multiplication">
                  <IncentiveCalculation
                    isLoading={isLoading}
                    base={{
                      title: t(`incentiveCalc.incentive.base`, String(defaultContent.incentiveCalc.incentive["base"]), {
                        month: getFullMonth(selectedPeriod, locale),
                      }),
                      amount: IncentiveCalulationData.RubyBV,
                    }}
                    multiplier={{
                      title: getIncentiveCalcMultiplierContent(titleKey, t),
                      amount: IncentiveCalulationData.IncentiveMultiplier,
                      icon: (
                        <LeafIconButton
                          Icon={<IconTable />}
                          HoverIcon={<IconTable color="blue" />}
                          aria-label="Table Icon Button"
                        />
                      ),
                      callbackFn: openMultiplierModal,
                      symbolIcon: <IconMultiply />,
                    }}
                    total={{
                      title: t(
                        `incentiveCalc.incentive.total`,
                        String(defaultContent.incentiveCalc.incentive["total"]),
                      ),
                      amount: IncentiveCalulationData.Total,
                      symbolIcon: <IconEquals />,
                    }}
                  />
                </div>
              </div>
              <div className="Ppe__section-padding Ppe__requirements">
                <div>
                  <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>
                </div>
                <div className="Ppe__requirements-content">
                  <RequirementTileMapper
                    isLoading={isLoading}
                    requirements={Requirements}
                    content={defaultContent}
                    translation="ppe"
                  />
                  <div className="Ppe__requirements-content--ruby">
                    <RubyPerformanceTile
                      isLoading={isLoading}
                      rubyRequirements={PPERubyRequirements}
                      maxBonusPercent={ppeOverviewData.MaxBonusPercent}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      <section className="Ppe__footer">
        <BonusInfoFooter />
      </section>
      <MultiplierMatrix
        data={ppeOverviewData.MultiplierMatrix}
        multiplier={ppeOverviewData.IncentiveCalculation.IncentiveMultiplier}
        isLoading={isLoading}
        open={showMultiplierModal}
        closeButtonCallback={closeMultiplierModal}
      />
    </main>
  );
}
