const requestExportDetails = () => {
  document.dispatchEvent(
    new CustomEvent("RequestExportDetails", {
      bubbles: true,
      composed: true,
      detail: {
        moduleId: "los",
        exportSelectedKPIS: "false",
        isFullMap: "false",
      },
    }),
  );
};

const ServerSideExportMenu = () => {
  // Placeholder
  return <button onClick={requestExportDetails}>Export</button>;
};

export default ServerSideExportMenu;
