import { Trans, useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import { TrackProgressTile } from "../../../../common/components/TrackProgressTile/TrackProgressTile";
import defaultContent from "../../../../content/annualPGG";

type Props = {
  isLoading: boolean;
  MonthlyRequirementsMet: number;
  MonthlyRequirementsTotal: number;
  MonthlyRequirementMetStatuses: boolean[];
  AnnualRequirementsMet: number;
  AnnualRequirementsTotal: number;
  AnnualRequirementMetStatuses: boolean[];
};

export default function TrackProgress({
  isLoading,
  MonthlyRequirementsMet,
  MonthlyRequirementsTotal,
  MonthlyRequirementMetStatuses,
  AnnualRequirementsMet,
  AnnualRequirementsTotal,
  AnnualRequirementMetStatuses,
}: Props) {
  const { t } = useTranslation(["annualPGG"]);
  const title = t("progressTile.title", defaultContent.progressTile.title).toString();
  const subtext = (
    <Trans>
      {t("progressTile.requirementLabel", defaultContent.progressTile["requirementLabel"], {
        current: MonthlyRequirementsMet + AnnualRequirementsMet,
        needed: MonthlyRequirementsTotal + AnnualRequirementsTotal,
      })}
      <span
        className={
          MonthlyRequirementsTotal + AnnualRequirementsTotal === MonthlyRequirementsMet + AnnualRequirementsMet
            ? "Tracking__bold Tracking__met"
            : "Tracking__bold"
        }
      ></span>
    </Trans>
  );
  const circlesSection = () => {
    return (
      <div className="Tracking__sections">
        {MonthlyRequirementsTotal > 0 && (
          <div className="Tracking__section">
            <div className="Tracking__section-title" tabIndex={0}>
              <Trans>
                {t("progressTile.monthlyProgress", defaultContent.progressTile["monthlyProgress"], {
                  current: MonthlyRequirementsMet,
                  needed: MonthlyRequirementsTotal,
                })}
                <span
                  className={
                    MonthlyRequirementsTotal === MonthlyRequirementsMet
                      ? "Tracking__bold Tracking__met"
                      : "Tracking__bold"
                  }
                ></span>
              </Trans>
            </div>
            <div className="Tracking__icons">
              {MonthlyRequirementMetStatuses &&
                MonthlyRequirementMetStatuses.map((isReqMet, index) => (
                  <div key={index} tabIndex={0}>
                    <IconCheckCircle
                      key={index}
                      id={`Monthly-Tracking__icon-${index.toString()}`}
                      color={isReqMet ? "green" : "gray"}
                      size="xlg"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        {AnnualRequirementsTotal > 0 && MonthlyRequirementsTotal > 0 && (
          <>
            <div className="vertical-divider"></div>
            <div className="divider-line"></div>
          </>
        )}
        {AnnualRequirementsTotal > 0 && (
          <div className="Tracking__section">
            <div className="Tracking__section-title" tabIndex={0}>
              <Trans>
                {t("progressTile.annualProgress", defaultContent.progressTile["annualProgress"], {
                  current: AnnualRequirementsMet,
                  needed: AnnualRequirementsTotal,
                })}
                <span
                  className={
                    AnnualRequirementsTotal === AnnualRequirementsMet
                      ? "Tracking__bold Tracking__met"
                      : "Tracking__bold"
                  }
                ></span>
              </Trans>
            </div>
            <div className="Tracking__icons">
              {AnnualRequirementMetStatuses &&
                AnnualRequirementMetStatuses.map((isReqMet, index) => (
                  <div key={index} tabIndex={0}>
                    <IconCheckCircle
                      key={index}
                      id={`Annual-Tracking__icon-${index.toString()}`}
                      color={isReqMet ? "green" : "gray"}
                      size="xlg"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <TrackProgressTile
      isLoading={isLoading}
      id="PGG Tracking Progress Tile"
      title={title}
      circlesSection={circlesSection()}
      subtext={subtext}
    />
  );
}
