import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import LeafTabViews from "../../../../../common/leaf/LeafTabViews/LeafTabViews";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { setCustomerActiveTab } from "../../../../../reducers/profile";
import { CUSTOMER_PROFILE_TABS_ID } from "../../../../../common/enums/profile-enums";
import {
  profileTabClickTrack,
  orderHistoryClickTrack,
} from "../../../../../common/tealium/CustomerProfile/Profile/ProfileTabViewsTrack/IndexTrack";
import ProfileTab from "./ProfileTab/ProfileTab";
import OverviewTab from "./OverviewTab/OverviewTab";
import OrderHistoryTab from "./OrderHistoryTab/OrderHistoryTab";

const CustomerProfileTabViews = () => {
  const { t } = useTranslation(["profile"]);

  const { customerActiveTab, customerActiveTabs } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();

  const goToOrderHistory = () => {
    dispatch(setCustomerActiveTab(CUSTOMER_PROFILE_TABS_ID.ORDER_HISTORY));
    orderHistoryClickTrack();
  };

  const internalTabsView = [
    {
      id: CUSTOMER_PROFILE_TABS_ID.OVERVIEW,
      title: `${t(
        "customerProfile.overviewTab.overview",
        defaultContent["customerProfile"]["overviewTab"]["overview"],
      )}`,
      View: <OverviewTab viewAllOnClick={goToOrderHistory} />,
    },
    {
      id: CUSTOMER_PROFILE_TABS_ID.PROFILE,
      title: `${t("customerProfile.profileTab.profile", defaultContent["customerProfile"]["profileTab"]["profile"])}`,
      View: <ProfileTab />,
    },
    {
      id: CUSTOMER_PROFILE_TABS_ID.ORDER_HISTORY,
      title: `${t(
        "customerProfile.orderHistoryTab.orderHistory",
        defaultContent["customerProfile"]["orderHistoryTab"]["orderHistory"],
      )}`,
      View: <OrderHistoryTab />,
    },
  ];

  const tabsView = customerActiveTabs.map((id: CUSTOMER_PROFILE_TABS_ID) => ({
    ...internalTabsView.find((item) => item.id === id),
  }));

  const setViewOnClick = (customerActiveTab: CUSTOMER_PROFILE_TABS_ID) => {
    dispatch(setCustomerActiveTab(customerActiveTab));
    profileTabClickTrack(customerActiveTab);
  };

  return <LeafTabViews setViewOnClick={setViewOnClick} Views={tabsView} activeView={customerActiveTab} />;
};

export default CustomerProfileTabViews;
