import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeIncentives";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BBIMetric.scss";

type Props = {
  show?: boolean;
  data: BronzeOverviewResponse;
  isFetching: boolean;
};

const BBIMetric = ({ data, isFetching = true, show = true }: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);

  const { incomeData } = data;
  const { basis, multiplier, bonus } = incomeData || {};

  if (show === false) return <></>;

  return (
    <div className="bbi-metric">
      <div className="bbi-metric__content">
        <h2 className="bbi-metric__header">{`${t("metric.header", defaultContent["metric"]["header"])}`}</h2>
        <div className="bbi-metric__incentive-calculation">
          <IncentiveCalculation
            isLoading={isFetching}
            base={{
              title: `${t("metric.performanceBonus", defaultContent["metric"]["performanceBonus"])}`,
              amount: basis?.amountCurrency || "0",
            }}
            multiplier={{
              title: `${t("metric.bbiMultiplier", defaultContent["metric"]["bbiMultiplier"])}`,
              amount: `${multiplier}%`,
              symbolIcon: <IconMultiply />,
            }}
            total={{
              title: `${t("metric.currentTotal", defaultContent["metric"]["currentTotal"])}`,
              amount: bonus?.amountCurrency || "0",
              symbolIcon: <IconEquals />,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default BBIMetric;
