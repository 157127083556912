import { ReactNode, RefObject } from "react";
import LeafTile from "../LeafTile/LeafTile";
import "./LeafTileValue.scss";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { LeafIconButton } from "../LeafIconButton/LeafIconButton";
import { IconTable } from "../../icons/IconTable/IconTable";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  id: string;
  title: string;
  value: string;
  isLoading: boolean;
  icon?: ReactNode;
  iconAriaLabel?: string;
  symbolIcon?: ReactNode;
  callback?: Function;
  buttonRef?: RefObject<HTMLButtonElement>;
  hideDesktopIcon?: boolean;
  hideMobileIcon?: boolean;
}

const LeafTileValue = ({
  id,
  title,
  value,
  isLoading,
  icon,
  iconAriaLabel = "",
  symbolIcon,
  callback,
  buttonRef,
  hideMobileIcon = false,
  hideDesktopIcon = false,
}: Props) => {
  const renderButton = () => {
    if (icon && callback && !isLoading) {
      return (
        <LeafIconButton
          Icon={<IconTable />}
          HoverIcon={<IconTable color="blue" />}
          onClick={() => callback()}
          className={`LeafTileValue__button LeafTileValue__button${
            hideMobileIcon === true ? "--hide-mobile" : ""
          } LeafTileValue__button${hideDesktopIcon === true ? "--hide-desktop" : ""}`}
          aria-label={iconAriaLabel || "Table Icon Button"}
        >
          <span className="LeafTileValue__button-icon">{icon ? icon : <></>}</span>
        </LeafIconButton>
      );
    }
    return <></>;
  };
  return (
    <LeafTile id={id} isLoading={false}>
      <div className="LeafTileValue">
        <span className="LeafTileValue__symbol">{symbolIcon ? symbolIcon : <></>}</span>
        <span className="LeafTileValue__content-container">
          <LeafRectangleLoader isLoading={isLoading} height="3.375rem" width="100%">
            <p className="LeafTileValue__title">{title}</p>
            <p className="LeafTileValue__value">{value}</p>
          </LeafRectangleLoader>
        </span>
        {renderButton()}
      </div>
    </LeafTile>
  );
};

export default LeafTileValue;
