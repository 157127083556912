import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import "./LeafCustomerTypeTag.scss";

type Props = {
  businessNatureCode?: number;
  registrationChannelCd?: string;
};

const LeafCustomerTypeTag = (props: Props) => {
  const { businessNatureCode, registrationChannelCd } = props;
  const { t } = useTranslation(["profile"]);

  switch (businessNatureCode) {
    case 3:
      return (
        <span className="tag-customer">{`${t(
          "customerProfile.tags.member",
          defaultContent["customerProfile"]["tags"]["member"],
        )}`}</span>
      );
    case 17:
      return (
        <span className="tag-customer">
          {registrationChannelCd === "ShareBar"
            ? `${t("customerProfile.tags.shareBar", defaultContent["customerProfile"]["tags"]["shareBar"])}`
            : `${t("customerProfile.tags.guestCustomer", defaultContent["customerProfile"]["tags"]["guestCustomer"])}`}
        </span>
      );
    case 4:
      return (
        <span className="tag-customer">{`${t(
          "customerProfile.tags.customer",
          defaultContent["customerProfile"]["tags"]["customer"],
        )}`}</span>
      );
    default:
      return <></>;
  }
};

export default LeafCustomerTypeTag;
