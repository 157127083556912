import { useTranslation } from "react-i18next";
import LeafSearchInput from "../../../../../../common/leaf/LeafSearchInput/LeafSearchInput";
import { useAppSelector, useAppDispatch } from "../../../../../../store";
import defaultContent from "../../../../../../content/profile";
import { getLoginAffAbo } from "../../../../../../reducers/model/boot";
import useGetProfileOrders from "../../../../../../common/hooks/useGetProfileOrders";
import { updateSearchKeyword } from "../../../../../../reducers/order/profileOrders";
import "./OrderHistoryTab.scss";
import LeafProfileLoader from "../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import {
  searchOrderClickTrack,
  profileOrderHistoryTabErrorTrack,
} from "../../../../../../common/tealium/CustomerProfile/Profile/ProfileTabViewsTrack/OrderHistoryTabTrack";
import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { useGetProfileQuery } from "../../../../../../services/Profile/profileAPI";
import { getBonusPeriod } from "../../../../../../common/util/common";
import OrdersByPeriod from "../../../../../Customers/OrderHistory/OrderHistoryTable/OrdersByPeriod/OrdersByPeriod";
import { includeGuestCustomerBN } from "../../../../../../reducers/model/order";

const OrderHistoryTab = () => {
  const { t } = useTranslation(["profile"]);

  const dispatch = useAppDispatch();

  const { selectedPeriod } = useAppSelector((state) => state.period);

  const profileABO = useAppSelector((state) => state.profile.affAbo);
  const customerIds = Number(profileABO.split("-")[1]); // extract customer number only

  const boot = useAppSelector((state) => state.boot);
  const affAbo = getLoginAffAbo({ boot });

  const { isFetching: isFetchingProfile, data: profile } = useGetProfileQuery({
    period: selectedPeriod,
    affAbo: profileABO,
  });

  const {
    isFetching: isFetchingOrderList = false,
    nextPageNum,
    nextPeriod,
    pageSize,
    orderGroups,
    allOrderCount,
    orderSearchKeyword = "",
    isError = false,
  } = useAppSelector((state) => state.profileOrders);

  const enableGuestCustomer = boot?.configuration?.globalCML?.enableGuestCustomer;
  const showVCSOrderTag = boot?.configuration?.globalCML?.orderList?.showVCSOrderTag;
  const minDigit = boot?.configuration?.globalCML?.orderList?.search?.minDigit;

  const _nextPeriod = `${nextPeriod !== 0 ? nextPeriod : selectedPeriod}`;

  const profileOrdersQueryParams = {
    affAbo,
    pageSize,
    pageNum: nextPageNum,
    period: _nextPeriod,
    sortValue: "ORDER_DATE",
    sortOrder: "DESC",
    customerIds,
    searchBy: orderSearchKeyword,
    ...includeGuestCustomerBN(enableGuestCustomer),
  };

  const entryDate = profile?.aboInfo?.entryDate;
  const customerEntryDatePeriod = entryDate ? getBonusPeriod(new Date(entryDate)) : null;

  // auto pre-load the data for first time
  const haveNextPage =
    nextPageNum !== null && customerEntryDatePeriod !== null && _nextPeriod >= customerEntryDatePeriod;
  const isFetching = isFetchingOrderList === false && isFetchingProfile === false;
  const autoFetch = haveNextPage && isFetching === true && isError === false;

  // when component mounted, load the auto load page data
  useGetProfileOrders({ ...profileOrdersQueryParams, autoFetch });

  const updateSearch = (input: string) => {
    dispatch(updateSearchKeyword(input));
    searchOrderClickTrack();
  };

  const resetSearch = () => {
    dispatch(updateSearchKeyword(""));
  };

  if (isError === true) {
    profileOrderHistoryTabErrorTrack();
  }

  const LoaderView = () => {
    return <LeafProfileLoader row={pageSize || 10} card={true} isFetching={isFetchingOrderList} />;
  };

  const ErrorView = () => {
    return <LeafErrorMessage show={isError} />;
  };

  return (
    <div className="order-history-tab">
      <div className="order-history-tab__search-container">
        <div className="order-history-tab__search">
          <LeafSearchInput
            minDigit={minDigit}
            placeholder={`${t(
              "customerProfile.orderHistoryTab.search",
              defaultContent["customerProfile"]["orderHistoryTab"]["search"],
            )}`}
            callback={updateSearch}
            clearOnClick={resetSearch}
            debounceMS={500}
            searchKeyword={orderSearchKeyword}
            key={orderSearchKeyword}
          />
        </div>
      </div>
      <div>
        <OrdersByPeriod
          orderGroups={orderGroups}
          filterApplied={false}
          searchApplied={orderSearchKeyword !== ""}
          showVCSOrderTag={showVCSOrderTag}
          isCompact={true}
          LoaderView={LoaderView}
          ErrorView={ErrorView}
        ></OrdersByPeriod>
      </div>
    </div>
  );
};

export default OrderHistoryTab;
