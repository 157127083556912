import { ReactNode, useEffect, useRef, useState } from "react";
import { Modal } from "@mui/base/Modal";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { IconClose } from "../../icons/IconClose/IconClose";
import "./LeafPanel.scss";
import commonContent from "../../../content/common";

export type ModalProps = {
  show: boolean;
  onCloseClick: Function;
  title: string;
  children?: ReactNode;
  position?: "right" | "left";
  exitIcon?: ReactNode;
  id: string;
  footer?: ReactNode;
  exitIconAriaLabel?: string;
  background?: "" | "cloud" | "veryLightGrey";
  showBackdrop?: boolean;
  previousElement?: HTMLElement;
  applyScroll?: boolean;
};

const LeafPanel = ({
  show,
  onCloseClick,
  title,
  children,
  position = "right",
  exitIcon,
  id,
  footer = null,
  exitIconAriaLabel = "",
  background = "",
  showBackdrop = true,
  previousElement,
  applyScroll = true,
}: ModalProps) => {
  const { t } = useTranslation(["common"]);
  const [modalOpenedByElement, setModalOpenedByElement] = useState<Element>();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonText = t("closeButton", commonContent["closeButton"]).toString();
  const [dragging, setDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  if (!exitIcon) {
    exitIcon = <IconClose width={"0.8125rem"} height={"1.25rem"} />;
  }
  if (!exitIconAriaLabel) {
    exitIconAriaLabel = closeButtonText;
  }

  useEffect(() => {
    if (show) {
      // capture the button that opened the modal so we can refocus it when modal closes
      setTimeout(() => {
        if (previousElement) {
          setModalOpenedByElement(previousElement);
        }
        if (closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, 100);
    }
  }, [previousElement, show]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onCloseClick(false);
    setTimeout(() => {
      if (modalOpenedByElement) {
        (modalOpenedByElement as HTMLElement).focus();
        setModalOpenedByElement(undefined);
      }
    }, 100);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const draggableElement = document.querySelector(".leaf-panel__draggable");
    const headerElement = document.querySelector(".leaf-panel__header");

    if (
      (draggableElement && draggableElement.contains(e.target as Node)) ||
      (headerElement && headerElement.contains(e.target as Node))
    ) {
      setStartY(e.touches[0].clientY);
      setDragging(true);
    } else {
      setDragging(false);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!dragging) return;

    const deltaY = e.touches[0].clientY - startY;

    if (deltaY > 0) {
      setCurrentY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (dragging && currentY > 200) {
      handleOnClose();
    }
    setDragging(false);
    setCurrentY(0);
  };

  const modalBackdrop = (
    <div
      tabIndex={-1}
      onClick={handleOnClose}
      className={`leaf-panel__backdrop ${showBackdrop ? "backdrop" : ""}`}
      id={id}
    ></div>
  );

  return show ? (
    <div
      className={`leaf-panel-modal__mobile-background${show === true ? "--displayed" : "--dismissed"}`}
      tabIndex={-1}
    >
      {show && ReactDOM.createPortal(modalBackdrop, document.body)}
      <Modal
        aria-labelledby="leaf-panel__header-title"
        open={show}
        className={`leaf-panel__${position} leaf-panel${
          show === true ? "--displayed" : "--dismissed"
        } leaf-panel__bg--${background} ${id}`}
        aria-modal={true}
        onClose={handleOnClose}
        aria-label={title}
        onTouchStart={applyScroll ? handleTouchStart : undefined}
        onTouchMove={applyScroll ? handleTouchMove : undefined}
        onTouchEnd={applyScroll ? handleTouchEnd : undefined}
        style={{
          transform: dragging && applyScroll ? `translateY(${currentY}px)` : "none",
          transition: dragging && applyScroll ? "none" : "transform 0.3s ease-out",
        }}
      >
        <div className="leaf-panel__container">
          {applyScroll && (
            <div className="leaf-panel__draggable">
              <span className="leaf-panel__draggable--line" />
            </div>
          )}

          <div className="leaf-panel__header">
            <h1 className="leaf-panel__header-title">{title}</h1>
            <button
              tabIndex={0}
              ref={closeButtonRef}
              className="leaf-panel__closeBtn"
              aria-label={exitIconAriaLabel}
              onClick={handleOnClose}
              id="leafPanelCloseButton"
              onKeyDown={handleKeyDown}
            >
              {exitIcon}
            </button>
          </div>

          <div className="leaf-panel__body">{children}</div>
          {footer && <div className="leaf-panel__footer">{footer}</div>}
        </div>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default LeafPanel;
