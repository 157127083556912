import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";
import CustomerApplicantSlider from "./CustomerApplicantSlider/CustomerApplicantSlider";
import CustomerProfileSlider from "./CustomerProfileSlider/CustomerProfileSlider";

const CustomerProfile = () => {
  useTranslation([
    "profile",
    "order",
    "orderType",
    "vcsReasons",
    "leafCustomerPV",
    "leafErrorMessage",
    "leafSortInfo",
    "orderHistoryNoDataMsg",
    "common",
  ]);

  const boot = useAppSelector((state) => state.boot);
  const showCustomerProfile = boot?.configuration?.profile?.showCustomerProfile;

  if (showCustomerProfile === true) {
    return (
      <>
        <CustomerProfileSlider />
        <CustomerApplicantSlider />
      </>
    );
  }
  return <></>;
};

export default CustomerProfile;
