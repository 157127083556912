import { useTranslation } from "react-i18next";
import { BonusHist, LeafBadgeGrid } from "../../../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import defaultContent from "../../../../../content/bronzeIncentives";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { Eligibility } from "../../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import { getBronzeDisplayDate } from "../../BronzeDate/BronzeDate";
import { getViewId } from "../../BronzeIncentivesViews/BronzeIncentivesViews";
import "./BIEligibilitySliderBody.scss";

type Props = {
  isShowSlider: boolean;
  data: Eligibility;
  isFetching: boolean;
  awardCode: number;
};

const BIEligibilitySliderBody = (props: Props) => {
  const { isShowSlider, data, isFetching = true, awardCode } = props;
  const { t } = useTranslation(["bronzeIncentives"]);

  if (isShowSlider === false) {
    return <></>;
  }

  const getHistory = () => {
    const { months = [] } = data || {};
    return months.map((el) => ({ status: el.status, period: el.period })) as BonusHist[];
  };

  if (isFetching === true) {
    return (
      <div className="bi-eligibility-slider-loader">
        <div className="bi-eligibility-slider-loader__card">
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="4rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="50%" height="2rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="50%" height="2rem" />
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="30.5rem" />
        </div>
        <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="2rem" />
        <div className="bi-eligibility-slider-loader__card">
          <LeafRectangleLoader isLoading={isFetching} numRows={1} width="95%" height="8rem" />
        </div>
      </div>
    );
  }

  const viewId = getViewId(awardCode);
  const incentiveType = `${t(`bronzeIncentives:${viewId}`, defaultContent[viewId])}`;

  return (
    <div className="bi-eligibility-slider-body">
      <div className="bi-eligibility-slider-body__eligibility-info">
        <div className="bi-eligibility-slider-body__eligibility-info__header">
          <p>{`${t(
            "eligibilitySlider.eligibleDescription",
            defaultContent["eligibilitySlider"]["eligibleDescription"],
            {
              months: data?.programDuration,
              incentiveType,
            },
          )}`}</p>
          <div className="bi-eligibility-slider-body__eligibility-info__dates">
            <span>
              <strong>
                {`${t("eligibilitySlider.startDateLabel", defaultContent["eligibilitySlider"]["startDateLabel"])}`}:
              </strong>{" "}
              {data && getBronzeDisplayDate(data.startPeriod)}
            </span>
            <span>
              <strong>
                {`${t("eligibilitySlider.endDateLabel", defaultContent["eligibilitySlider"]["endDateLabel"])}`}:
              </strong>{" "}
              {data && getBronzeDisplayDate(data.endPeriod)}
            </span>
          </div>
          {data?.notStarted === true && (
            <div>{`${t(
              "eligibilitySlider.notStartedMessage",
              defaultContent["eligibilitySlider"]["notStartedMessage"],
            )}`}</div>
          )}
        </div>
        {data?.notStarted === false && (
          <div className="bi-eligibility-slider-body__eligibility-info__badges_container">
            <LeafBadgeGrid history={getHistory()} />
          </div>
        )}
      </div>
      <div className="bi-eligibility-slider-body__eligibility-program-detail">
        <h2 className="bi-eligibility-slider-body__eligibility-program-detail__title">{`${t(
          "eligibilitySlider.footerTitle",
          defaultContent["eligibilitySlider"]["footerTitle"],
        )}`}</h2>
        <div className="bi-eligibility-slider-body__eligibility-program-detail__body">
          <p>{`${t(
            "eligibilitySlider.footerDescription",
            defaultContent["eligibilitySlider"]["footerDescription"],
          )}`}</p>
        </div>
      </div>
    </div>
  );
};

export default BIEligibilitySliderBody;
