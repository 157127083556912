import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import defaultContent from "../../../../content/bronzeIncentives";
import { BronzeView } from "../BronzeIncentivesViews/BronzeIncentivesViews";
import "./BronzeIncentivesNavigation.scss";
import { ShellConfigData } from "../../../../services/ShellConfig/ShellConfigDataLayer";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = {
  bronzeViews: BronzeView[];
  navButtonOnClick: (viewId: string) => void;
};

const BronzeIncentivesNavigation = (props: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { pathname } = useLocation();
  const { isLoading: isShellConfigLoading } = ShellConfigData({ pathname });

  const { bronzeViews, navButtonOnClick } = props;

  const _navButtonOnClick = (viewId: string) => {
    navButtonOnClick(viewId);
  };

  if (isShellConfigLoading === false && bronzeViews.length <= 1) {
    return <></>;
  }

  return (
    <nav aria-label={`${t("pageNavigation", defaultContent["pageNavigation"])}`}>
      <div className="BronzeIncentivesNavigation">
        <ul className="BronzeIncentivesNavigation__menu" role="menubar">
          {isShellConfigLoading ? (
            <li role="none">
              <span role="menuitem">
                <LeafRectangleLoader isLoading={true} width={"30rem"} height={"2rem"} />
              </span>
            </li>
          ) : (
            bronzeViews.map((view) => (
              <li role="none">
                <span role="menuitem">
                  <LeafFilterButton
                    key={view.id}
                    text={`${t(view.id, defaultContent[view.id])}`}
                    onButtonClick={() => _navButtonOnClick(view.id)}
                    theme={view.selected === false ? "inactive" : ""}
                  />
                </span>
              </li>
            ))
          )}
        </ul>
      </div>
    </nav>
  );
};

export default BronzeIncentivesNavigation;
