import { useTranslation } from "react-i18next";
import { useEffect, useState, Suspense } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import defaultContent from "../../content/bronzeIncentives";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import { useAppDispatch, useAppSelector } from "../../store";
import { resetOverviewDetails } from "../../reducers/bronzeIncentive";
import { tabMenuClickTrack } from "../../common/tealium/BronzeIncentive/TabMenuTrack";
import { useBronzeIncentivePageLoadTrack } from "../../common/tealium/BronzeIncentive/IndexTrack";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import BronzeIncentivesNavigation from "./BronzeCommon/BronzeIncentivesNavigation/BronzeIncentivesNavigation";
import BronzeIncentivesViews, { BronzeView } from "./BronzeCommon/BronzeIncentivesViews/BronzeIncentivesViews";
import BronzeBannerToast from "./BronzeBannerToast/BronzeBannerToast";
import BIEligibilitySlider from "./BronzeCommon/BIEligibilitySlider/BIEligibilitySlider";
import BronzeIncentivesLoader from "./BronzeCommon/BronzeIncentivesLoader/BronzeIncentivesLoader";
import "./BronzeIncentives.scss";

export const BronzeIncentiveContainer = () => {
  return (
    <main className="bronze-incentives-container">
      <Suspense fallback={<BronzeIncentivesLoader fullpage={true} />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

const BronzeIncentives = () => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { hash, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const eligibleIncentives = useAppSelector((store) => store?.boot?.shellConfig?.eligibleIncentives);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const [bronzeViews, setBronzeViews] = useState<BronzeView[]>([]);

  const initialBronzeViews = BronzeIncentivesViews();
  if (initialBronzeViews.length > 0 && bronzeViews.length < 1) {
    setBronzeViews(initialBronzeViews);
  }

  const selectedView = bronzeViews.find((view) => view.selected === true);

  useEffect(() => {
    dispatch(resetOverviewDetails());
  }, []);

  useEffect(() => {
    if (initialBronzeViews.length < 1) {
      navigate(ROUTE_PATHS.PERFORMANCE_DASHBOARD);
    }
    if (selectedView?.id && initialBronzeViews.find((x) => x.id === selectedView?.id)) {
      // On period change, when any active program added, keep current view selected
      const updatedBronzeViews = initialBronzeViews.map((item, index) => ({
        ...item,
        selected: item.id === selectedView.id,
      }));
      setBronzeViews(updatedBronzeViews);
    } else {
      // On period change, when any active program removed, redirect page to first active view.
      const currentView = initialBronzeViews.find((view) => view.selected === true);
      if (currentView?.id) {
        window.location.hash = currentView.id;
      }
      setBronzeViews(initialBronzeViews);
    }
  }, [eligibleIncentives]);

  const updateViews = (viewId: string) => {
    const updatedViews = bronzeViews.map((view: BronzeView) => ({
      ...view,
      selected: view.id === viewId,
    }));
    setBronzeViews(updatedViews);
  };

  const navButtonOnClick = (viewId: string) => {
    tabMenuClickTrack(viewId, selectedView ? selectedView.id : "");
    window.location.hash = viewId;
    updateViews(viewId);
    dispatch(resetOverviewDetails());
  };

  useEffect(() => {
    if (hash) {
      const viewId = hash.substring(1);
      updateViews(viewId);
    } else if (selectedView) {
      window.location.hash = selectedView.id;
    }
  }, []);

  // tealium tagging: page load event
  useBronzeIncentivePageLoadTrack({ period: selectedPeriod, currentView: selectedView ? selectedView.id : "" });

  return (
    <div className="bronze-incentives">
      <BronzeBannerToast />
      <PageTitleSection title={selectedView ? `${t(selectedView.id, defaultContent[selectedView.id])}` : ""} />
      <BronzeIncentivesNavigation bronzeViews={bronzeViews} navButtonOnClick={navButtonOnClick} />

      <div className="bronze-incentives__viwes">{selectedView && selectedView.BronzeView}</div>
      <BonusInfoFooter />
      <BIEligibilitySlider />
    </div>
  );
};

export default BronzeIncentives;
