import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import ServerSideExportMenu from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";

type Props = {
  open: boolean;
};

export const LOSExportModal = (props: Props) => {
  const { open } = props;
  return (
    <div className="los-export-modal">
      <LeafModal open={open} children={<ServerSideExportMenu />}></LeafModal>;
    </div>
  );
};
