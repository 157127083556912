import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { updateSelectorType, SELECTOR_TYPE } from "../../reducers/period";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import { getPerformanceYear } from "../../common/util/period";
import "./VisionBonus.scss";
import defaultContent from "../../content/visionBonus";
import { useAppDispatch, useAppSelector } from "../../store";
import TwoBadgesRequirementTile from "../../common/components/RequirementTiles/TwoBadgesRequirementTile/TwoBadgesRequirementTile";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { BadgeRequirementTile } from "../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { DetailsListModel } from "../../common/components/Modals/DetailsListModal/DetailsListModal";
import VisionBonusBanner from "./VisionBonusBanner/VisionBonusBanner";
import VisionBonusFormula from "./VisionBonusFormula/VisionBonusFormula";
import VisionBonusProgress from "./VisionBonusProgress/VisionBonusProgress";

const mockData = {
  fq: {
    isFqLoading: false,
    isFqExpanded: false,
    basis: {
      amountCurrency: "$10",
    },
    multiplier: {
      amountCurrency: "10%",
    },
    bonus: {
      amountCurrency: "$10",
    },
  },
  qc: {
    isQcLoading: false,
    isQcExpanded: false,
    basis: {
      amountCurrency: "$5",
    },
    multiplier: {
      amountCurrency: "5%",
    },
    bonus: {
      amountCurrency: "$5",
    },
  },
  requirements: {
    totalRequirements: 3,
    requirementsMet: 2,
    requirementMetStatuses: [true, false, true],
  },
};

const VisionBonus = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["visionBonus"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = useAppSelector((store) => store.boot?.configuration?.dashboardLinks?.learnMore?.visionBonus);
  const [showRequirementViewDetailModal, setRequirementViewDetailModal] = useState(false);
  const [showDateRangeError, setShowDateRangeError] = useState(false);

  const viewDetailButtonOnClick = () => {
    setRequirementViewDetailModal(true);
  };

  const onModalViewButtonClose = () => {
    setRequirementViewDetailModal(false);
  };

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
    // this should be return from api or config when read current Period or PY
    // simple check py for now
    // selectedPy return YYYY
    // mockData.showDateRangeError : boolean;
    const selectedPy = Number(getPerformanceYear(selectedPeriod));
    setShowDateRangeError(false);
    if (![2025, 2026, 2027].includes(selectedPy)) {
      setShowDateRangeError(true);
    }
  }, [dispatch, selectedPeriod]);

  if (showDateRangeError) {
    return <ErrorPage errorType={ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD} isLoading={false} />;
  }

  return (
    <div className="vision-bonus">
      <div className="vision-bonus__header">
        <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
      </div>
      <div className="vision-bonus__content">
        <div className={"vision-bonus__content__overview"}>
          <VisionBonusBanner isLoading={false} learnMoreLink={learnMoreLink} status={"ELIGIBLE"} />
          <VisionBonusProgress data={mockData.requirements} isLoading={false} />
        </div>
        <div className={"vision-bonus__content__calculation"}>
          <VisionBonusFormula data={mockData} />
        </div>
        <div className={"vision-bonus__content__requirement"}>
          <h2 className={"vision-bonus__content__requirement__header"}>
            {`${t("requirements.sectionHeader", defaultContent["requirements"]["sectionHeader"])}`}
          </h2>
          <div className={"vision-bonus__content__requirement__content"}>
            <BadgeRequirementTile
              title={`${t(
                "requirements.maintainPinTile.maintainPin",
                defaultContent.requirements.maintainPinTile.maintainPin,
              )}`}
              description={""}
              status={QUALIFICATION_STATUS.QUALIFIED}
              hideDescription={true}
            />
            <BadgeRequirementTile
              title={`${t(
                "requirements.maintainGARAwardTile.maintainGARAward",
                defaultContent.requirements.maintainGARAwardTile.maintainGARAward,
              )}`}
              description={""}
              status={QUALIFICATION_STATUS.QUALIFIED}
              hideDescription={true}
            />
            <TwoBadgesRequirementTile
              isLoading={false}
              badge1={{
                title: `${t(
                  "requirements.fqOrQcGrowthTile.fqGrowth",
                  defaultContent.requirements.fqOrQcGrowthTile.fqGrowth,
                )}`,
                met: true,
                eligible: true,
              }}
              badge2={{
                title: `${t(
                  "requirements.fqOrQcGrowthTile.qcGrowth",
                  defaultContent.requirements.fqOrQcGrowthTile.qcGrowth,
                )}`,
                met: false,
                eligible: true,
              }}
              viewDetailButton={{
                text: `${t(
                  "requirements.fqOrQcGrowthTile.viewDetails",
                  defaultContent.requirements.fqOrQcGrowthTile.viewDetails,
                )}`,
                buttonOnClick: viewDetailButtonOnClick,
              }}
              title={`${t(
                "requirements.fqOrQcGrowthTile.fqOrQcGrowth",
                defaultContent.requirements.fqOrQcGrowthTile.fqOrQcGrowth,
              )}`}
            />

            <DetailsListModel
              multiplierBaseReponseData={{
                isLoading: false,
                errorStatus: false,
                refetch: () => {},
              }}
              modalIsOpen={showRequirementViewDetailModal}
              toggleModal={onModalViewButtonClose}
              title={`${t("FqQcStatusModal.title", defaultContent["FqQcStatusModal"]["title"])}`}
              table={[
                {
                  sectionHeaderIcon: false,
                  sectionHeaderText: `${t(`FqQcStatusModal.fqStatus`, defaultContent["FqQcStatusModal"]["fqStatus"])}`,
                  achieved: false,
                  displaySectionHeader: true,
                  tableHeader: [],
                  tableRows: [
                    {
                      row: [
                        {
                          value: `${t(`FqQcStatusModal.pyFQs`, defaultContent["FqQcStatusModal"]["pyFQs"], {
                            performanceYear: "PY24",
                          })}`,
                          columnAlign: "left",
                        },
                        { value: "10$", columnAlign: "right" },
                      ],
                    },
                    {
                      row: [
                        {
                          value: `${t(`FqQcStatusModal.currentYtd`, defaultContent["FqQcStatusModal"]["currentYtd"])}`,
                          columnAlign: "left",
                        },
                        { value: "10$", columnAlign: "right" },
                      ],
                    },
                    {
                      row: [
                        {
                          value: `${t(`FqQcStatusModal.tracking`, defaultContent["FqQcStatusModal"]["tracking"])}`,
                          columnAlign: "left",
                        },
                        { value: "10$", columnAlign: "right" },
                      ],
                    },
                  ],
                },
                {
                  sectionHeaderIcon: false,
                  sectionHeaderText: `${t(`FqQcStatusModal.qcStatus`, defaultContent["FqQcStatusModal"]["qcStatus"])}`,
                  achieved: false,
                  displaySectionHeader: true,
                  tableHeader: [],
                  tableRows: [
                    {
                      row: [
                        {
                          value: `${t(`FqQcStatusModal.pyQCs`, defaultContent["FqQcStatusModal"]["pyQCs"], {
                            performanceYear: "PY24",
                          })}`,
                          columnAlign: "left",
                        },
                        { value: "10$", columnAlign: "right" },
                      ],
                    },
                    {
                      row: [
                        {
                          value: `${t(`FqQcStatusModal.currentYtd`, defaultContent["FqQcStatusModal"]["currentYtd"])}`,
                          columnAlign: "left",
                        },
                        { value: "10$", columnAlign: "right" },
                      ],
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="vision-bonus__footer">
        <BonusInfoFooter />
      </div>
    </div>
  );
};

export default VisionBonus;
