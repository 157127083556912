import { ReactNode, useEffect, useState } from "react";
import { IconArrowUp } from "../../icons/IconArrowUp/IconArrowUp";
import { IconArrowDropdown } from "../../icons/IconArrowDropdown/IconArrowDropdown";
import LeafTileDivider from "../LeafTileDivider/LeafTileDivider";
import "./LeafCollapsibleSection.scss";

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  isExpanded?: boolean;
  titleExpand?: string;
  titleCollapse?: string;
  children?: ReactNode;
  onClickFn?: Function;
}

export const LeafCollapsibleSection = ({
  isExpanded = false,
  titleExpand = "Expand",
  titleCollapse = "Collapse",
  onClickFn,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const toggleExpandedContent = () => {
    setExpanded(!expanded);
    if (onClickFn) {
      onClickFn(expanded);
    }
  };

  const renderExpandedContent = () => {
    return (
      <>
        <button aria-label="Collapse Button" className="Lcs__collapse-message" onClick={toggleExpandedContent}>
          <span className="Lcs__message-text">{titleCollapse}</span>
          <span className="Lcs__message-icon">
            <IconArrowUp backgroundClass={"blue"} thickness="thick" />
          </span>
        </button>
        <LeafTileDivider />
        <div className="Lcs__children">{children}</div>
      </>
    );
  };

  const renderExpandMessage = () => {
    return (
      <button aria-label="Expand Button" className="Lcs__expand-message" onClick={toggleExpandedContent}>
        <span className="Lcs__message-text">{titleExpand}</span>
        <span className="Lcs__message-icon">
          <IconArrowDropdown backgroundClass={"blue"} thickness="thick" />
        </span>
      </button>
    );
  };

  return (
    <div className={"Lcs Lcs__" + (expanded ? "expanded" : "collapsed")}>
      {expanded ? renderExpandedContent() : renderExpandMessage()}
    </div>
  );
};
