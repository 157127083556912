const content: { [key: string]: any } = {
  newSiteTitle: "MyBiz",
  earned: "Earned",
  notEarned: "Not Earned",
  potential: "Potential",
  unavailable: "Unavailable",
  paydayAchieved: "Payday",
  paydayPotential: "Potential\xa0Payday",
  open: "Open",
  ppv: "PPV",
  vcspv: "VCS PV",
  pcpv: "PCPV",
  papv: "PAPV",
  gpv: "GPV",
  vcs: "VCS",
  ruby: "Ruby PV",
  totalsAsOf: "Totals as of {timestamp}",
  target: "Target",
  requalification: "Requalification",
  viewAll: "View All",
  moreInfo: "More Info",
  exempt: "Exempt",
  awardLabels: {
    "no-pin-level": "--",
    diamond: "Diamond",
    "diamond-plus": "Diamond Plus",
    "founders-emerald": "Founders Emerald",
    emerald: "Emerald",
    "founders-sapphire": "Founders Sapphire",
    sapphire: "Sapphire",
    "founders-ruby": "Founders Ruby",
    ruby: "Ruby",
    "founders-platinum": "Founders Platinum",
    platinum: "Platinum",
    "gold-producer": "Gold Producer",
    "silver-producer": "Silver Producer",
    "silver-sponsor": "Silver Sponsor",
    associate: "Associate",
    inactive: "Inactive",
    member: "Member",
    "preferred-customer": "Preferred Customer",
    "founders-diamond": "Founders Diamond",
    "executive-diamond": "Executive Diamond",
    "founders-executive-diamond": "Founders Executive Diamond",
    "double-diamond": "Double Diamond",
    "founders-double-diamond": "Founders Double Diamond",
    "triple-diamond": "Triple Diamond",
    "founders-triple-diamond": "Founders Triple Diamond",
    crown: "Crown",
    "founders-crown": "Founders Crown",
    "crown-ambassador": "Crown Ambassador",
    "founders-crown-ambassador": "Founders Crown Ambassador",
    "percent-23": "23%",
    "percent-21": "21%",
    "percent-18": "18%",
    "percent-15": "15%",
    "percent-12": "12%",
    "percent-9": "9%",
    "percent-6": "6%",
    "percent-3": "3%",
    "23-percent": "23%",
    "21-percent": "21%",
    "18-percent": "18%",
    "15-percent": "15%",
    "12-percent": "12%",
    "9-percent": "9%",
    "6-percent": "6%",
    "3-percent": "3%",
    "not-available": "Not Available",
    "fca-40": "FCA 40",
    "fca-45": "FCA 45",
    "fca-50": "FCA 50",
    "fca-55": "FCA 55",
    "fca-60": "FCA 60",
    "fca-65": "FCA 65",
    "fca-70": "FCA 70",
    "not-tracking": "Not Tracking",
    ads: "ADS",
    adr: "ADR",
    pearl: "Pearl",
    extension: "or Above",
    "platinum-or-above": "Platinum or Above",
    "gar-executive-diamond": "GAR Executive Diamond",
    "gar-founders-executive-diamond": "GAR Founders Executive Diamond",
    "gar-double-diamond": "GAR Double Diamond",
    "gar-founders-double-diamond": "GAR Founders Double Diamond",
    "gar-triple-diamond": "GAR Triple Diamond",
    "gar-founders-triple-diamond": "GAR Founders Triple Diamond",
    "gar-crown": "GAR Crown",
    "gar-founders-crown": "GAR Founders Crown",
    "gar-crown-ambassador": "GAR Crown Ambassador",
    "gar-founders-crown-ambassador": "GAR Founders Crown Ambassador",
    silver: "Silver",
    gold: "Gold Producer",
    foundersPlatinum: "Founders Platinum",
    foundersSapphire: "Founders Sapphire",
    inMarketEmerald: "Emerald",
    inMarketFoundersEmerald: "Founders Emerald",
    inMarketDiamond: "Diamond",
    inMarketFoundersDiamond: "Founders Diamond",
    a70inMarketEmerald: "A70 Emerald",
    a70inMarketFoundersEmerald: "A70 Founders Emerald",
    a70inMarketDiamond: "A70 Diamond",
    a70inMarketFoundersDiamond: "A70 Founders Diamond",
  },
  abbreviatedAwardLabels: {
    "0P": "0P",
    SI6: "SI6",
    SI4: "SI4",
    GLD: "GLD",
    DD: "DD",
    RBY: "RBY",
    FDD: "FDD",
    FRB: "FRB",
    PRL: "PRL",
    SPH: "SPH",
    FSP: "FSP",
    EM: "EM",
    FEM: "FEM",
    DIA: "DIA",
    FDI: "FDI",
    XDI: "XDI",
    FED: "FED",
    DDI: "DDI",
    F2D: "F2D",
    TDI: "TDI",
    F3D: "F3D",
    CRN: "CRN",
    FCN: "FCN",
    CAM: "CAM",
    FCA: "FCA",
    F40: "F40",
    F45: "F45",
    F50: "F50",
    F55: "F55",
    F60: "F60",
    F65: "F65",
    F70: "F70",
    "03P": "03P",
    "6P": "6P",
    "9P": "9P",
    "12P": "12P",
    "15P": "15P",
    "18P": "18P",
    "21P": "21P",
    "23P": "23P",
    GXD: "GXD",
    GFE: "GFE",
    G2D: "G2D",
    GF2: "GF2",
    GTD: "GTD",
    GF3: "GF3",
    GCN: "GCN",
    GFC: "GFC",
    GCA: "GCA",
    GFA: "GFA",
  },
  setButtonText: "Set",
  closeButton: "Close",
  backButton: "Back",
  learnMore: "Learn More",
  bonusesDisclaimer:
    "Bonuses and discretionary incentives earned are subject to verification by Amway. To be eligible for bonuses and incentives, an Amway Business Owner (ABO) must be in compliance at all times with the Amway ABO Compensation Plan (Core Plan) and the ABO Rules of Conduct.",
  totalsDisclaimer:
    "Totals reflect current business status. Information subject to change based on business activity. Intervening events could affect final monthly and annual totals.",
  footer: "+ Amway Core Plus discretionary incentives program",
  incentivesProgram: "* Amway Core Plus discretionary incentives program.",
  marketDisclaimer: "",
  discretionary:
    "Discretionary Incentives are separate from the IBO Compensation Plan and con vary form year to year. See <1>{link}</1> for complete details",
  legCount: "Leg {count}",
  legCountCompleted: "Leg {count} completed",
  legCountIncomplete: "Leg {count} incomplete",
  performanceYearShort: "PY",
  accessibilityLabels: {
    circleCheck: "Circle Check Icon",
  },
} as const;

export default content;
