import { useTranslation } from "react-i18next";
import LeafPreferredLanguageInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafPreferredLanguageInfo/LeafPreferredLanguageInfo";
import LeafRecentReturnDateInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRecentReturnDateInfo/LeafRecentReturnDateInfo";
import LeafRecentOrderDateInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRecentOrderDateInfo/LeafRecentOrderDateInfo";
import LeafRenewalDateInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRenewalDateInfo/LeafRenewalDateInfo";
import LeafDateJoinedInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafDateJoinedInfo/LeafDateJoinedInfo";
import LeafBirthdayInfo from "../../../../../../../common/leaf/LeafProfile/LeafInfo/LeafBirthdayInfo/LeafBirthdayInfo";
import defaultContent from "../../../../../../../content/profile";
import { useAppSelector } from "../../../../../../../store";
import "./CustomerAdditionalInfo.scss";

type Props = {
  privacyFlag: boolean;
  recentOrderDate: string;
  recentReturnDate: string;
  isoLanguageCode: string;
  isoBirthdate: string;
  entryDate: string;
  renewalDate: string;
};

const CustomerAdditionalInfo = (props: Props) => {
  const { t } = useTranslation(["profile"]);
  const { privacyFlag, recentOrderDate, recentReturnDate, isoLanguageCode, isoBirthdate, entryDate, renewalDate } =
    props;

  const boot = useAppSelector(({ boot }) => boot);

  const { showBirthday, showEntryDate, showLanguage, showRecentOrderDate, showRecentReturnOrderDate, showRenewalDate } =
    boot?.configuration?.profile?.customerInfo || {};

  return (
    <div className="customer-additional-info__container">
      <div className="customer-additional-info__header">
        {`${t(
          "customerProfile.profileTab.additionalInformation",
          defaultContent["customerProfile"]["profileTab"]["additionalInformation"],
        )}`}
      </div>
      <div className="customer-additional-info__content">
        <LeafRecentOrderDateInfo date={recentOrderDate} privacyFlag={privacyFlag} show={showRecentOrderDate} />
        <LeafRecentReturnDateInfo date={recentReturnDate} privacyFlag={privacyFlag} show={showRecentReturnOrderDate} />
        <LeafPreferredLanguageInfo isoLanguage={isoLanguageCode} privacyFlag={privacyFlag} show={showLanguage} />
        <LeafBirthdayInfo date={isoBirthdate} privacyFlag={privacyFlag} show={showBirthday} />
        <LeafDateJoinedInfo date={entryDate} privacyFlag={privacyFlag} show={showEntryDate} />
        <LeafRenewalDateInfo date={renewalDate} privacyFlag={privacyFlag} show={showRenewalDate} />
      </div>
    </div>
  );
};

export default CustomerAdditionalInfo;
