import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import LeafProfileLoader from "../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import LeafLinkButton from "../../common/leaf/LeafLinkButton/LeafLinkButton";
import { addNoteClickTrack, editNoteClickTrack } from "../../common/tealium/CustomerProfile/Note/NoteTrack";
import {
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useGetNoteQuery,
  useUpdateNoteMutation,
  util as noteAPIUtil,
} from "../../services/Note/noteAPI";
import defaultContent from "../../content/profile";
import ViewNote from "./ViewNote/ViewNote";
import EditNote from "./EditNote/EditNote";
import "./Note.scss";

const Note = () => {
  const { t } = useTranslation(["profile"]);

  const editButtonText = `${t(
    "customerProfile.note.editButton",
    defaultContent["customerProfile"]["note"]["editButton"],
  )}`;
  const saveButtonText = `${t(
    "customerProfile.note.saveButton",
    defaultContent["customerProfile"]["note"]["saveButton"],
  )}`;
  const addButtonText = `${t(
    "customerProfile.note.addButton",
    defaultContent["customerProfile"]["note"]["addButton"],
  )}`;

  const dispatch = useAppDispatch();

  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { showNote = false } = useAppSelector(({ boot }) => boot?.configuration) || {};

  const { isFetching, data } = useGetNoteQuery({ affAbo, limit: 1 });
  const { noteId = "", content = "" } = data?.notes[0] || {};

  const [updateNote] = useUpdateNoteMutation();
  const [createNote] = useCreateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedNote, setUpdatedNote] = useState("");

  const _isFetching = showNote === true && isFetching === true;

  // dispatch event to update getNote rtk query local cache
  const updateGetNoteQuery = (noteId: string, content: string) => {
    dispatch(
      noteAPIUtil.upsertQueryData(
        "getNote",
        {
          affAbo,
          limit: 1,
        },
        {
          notes: [
            {
              noteId,
              content,
            },
          ],
        },
      ),
    );
  };

  const createNewNote = async () => {
    if (!noteId && updatedNote) {
      updateGetNoteQuery("temp", updatedNote);

      const result = await createNote({
        affAbo,
        content: updatedNote,
      });

      if ("data" in result) {
        updateGetNoteQuery(result.data.noteId, updatedNote);
      }
    }
  };

  const updateNoteContent = async () => {
    if (noteId && updatedNote) {
      updateGetNoteQuery(noteId, updatedNote);
      await updateNote({
        noteId,
        affAbo,
        body: {
          content: updatedNote,
        },
      });
    }
  };

  const deleteNoteContent = async () => {
    if (noteId && updatedNote.length === 0) {
      updateGetNoteQuery("", updatedNote);
      await deleteNote({
        noteId,
        affAbo,
      });
    }
  };

  const addNoteButtonOnClick = () => {
    setIsEditing(true);
    addNoteClickTrack();
  };

  const editButtonOnClick = () => {
    setIsEditing(true);
    setUpdatedNote(content);
    editNoteClickTrack();
  };

  const saveNoteButtonOnClick = async () => {
    try {
      setIsUpdating(true);
      setIsEditing(false);
      await createNewNote();
      await updateNoteContent();
      await deleteNoteContent();
      setIsUpdating(false);
    } catch (error) {
      console.error();
    }
  };

  const noteOnChange = (note: string) => {
    setUpdatedNote(note);
  };

  if (_isFetching === false) {
    return (
      <div className="note note__container">
        <div className="note__container__content">
          <div className={`note__title${noteId || isEditing ? "" : "--hidden"}`}>
            <h2 className="note__title__text">{`${t(
              "customerProfile.note.pageTitle",
              defaultContent["customerProfile"]["note"]["pageTitle"],
            )}`}</h2>
            <LeafLinkButton
              onClick={editButtonOnClick}
              label={editButtonText}
              theme="blue"
              fontWeight="bold"
              fontSize="medium"
              show={isEditing === false}
              disabled={isUpdating === true}
            >
              {editButtonText}
            </LeafLinkButton>
            <LeafLinkButton
              onClick={saveNoteButtonOnClick}
              label={saveButtonText}
              theme="blue"
              fontWeight="bold"
              fontSize="medium"
              show={isEditing === true}
              disabled={isUpdating === true}
            >
              {saveButtonText}
            </LeafLinkButton>
          </div>
          <div className={`note__container__content__viewNote${isEditing ? "--hidden" : ""}`}>
            <ViewNote noteId={noteId} content={content} />
          </div>
          <EditNote value={content} noteOnChange={noteOnChange} show={isEditing} />
        </div>
        <div className="note__container__content-btn">
          <LeafLinkButton
            onClick={addNoteButtonOnClick}
            label={addButtonText}
            as="button"
            fontWeight="bold"
            fontSize="medium"
            show={noteId === "" && isEditing === false}
            disabled={isUpdating === true}
          >
            {addButtonText}
          </LeafLinkButton>
        </div>
      </div>
    );
  }

  return (
    <div className="note__container__loader">
      <LeafProfileLoader row={1} card={true} isFetching={isFetching} />
    </div>
  );
};

export default Note;
