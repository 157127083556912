export enum DROPDOWN_LABELS {
  PERFORMANCE = "performance",
  EARLY_INCENTIVES = "earlyIncentives",
  MY_GROUP = "myGroup",
  LEADER_GROWTH = "leaderGrowth",
  RESOURCES = "resources",
  ACADEMY = "academy",
}

export enum DROPDOWN_KEYS {
  PERFORMANCE = "performance",
  EARLY_INCENTIVES = "earlyIncentives",
  MY_GROUP = "myGroup",
  LEADER_GROWTH = "leaderGrowth",
  RESOURCES = "resources",
  ACADEMY = "academy",
}

export enum ROUTE_LABELS {
  PERFORMANCE_BONUS = "performanceBonus",
  EARNINGS_CALCULATOR = "earningsCalculator",
  CSI = "csi",
  BF_PLUS = "bronzeFoundation",
  BRONZE_FOUNDATION_INCENTIVE = "bronzeFoundationIncentive",
  BB_PLUS = "bronzeBuilder",
  BRONZE_BUILDER_INCENTIVE = "bronzeBuilderIncentive",
  CUSTOMER_OVERVIEW = "customerOverview",
  LOS = "los",
  EDLOS = "edlos",
  ACTION_REPORTS = "actionReports",
  WATCHLIST = "watchlist",
  PERFORMANCE_PLUS = "performancePlus",
  PERFORMANCE_ELITE = "performanceElite",
  PPE = "performancePlusElite",
  PGG = "personalGrowth",
  MONTHLY_PGG = "personalGrowthMonthly",
  MONTHLY_PGGI = "monthlyPGGI",
  ANNUAL_PGG = "personalGrowthAnnual",
  PGG_GCR = "personalGroupGrowthGCR",
  PERSONAL_GROUP_GROWTH = "personalGroupGrowth",
  FGG = "frontlineGrowth",
  TTCI = "twoTimeCashIncentive",
  FEEDBACK = "feedback",
  LEARN_ABOUT_CPP = "learnAboutCpp",
  LOG_OUT = "logOut",
  HAT = "hat",
  FAA = "faa",
  LEADERSHIP_SEMINAR = "leadershipSeminar",
  RUBY = "ruby",
  LEADERSHIP = "leadership",
  FOSTER = "fosterString",
  INTERNATIONAL_LEADERSHIP = "internationalLeadership",
  DEPTH = "depth",
  CBR = "cbrActionReport",
  CVR = "cvrActionReport",
  IBO_ONBOARDING = "iboOnboardingActionReport",
  PATH_TO_BRONZE = "newAboActivation",
  BIRTHDAYS_ACTION_REPORT = "birthdaysActionReport",
  WHOS_NEW_ACTION_REPORT = "whosNewActionReport",
  WHOS_CLOSE_ACTION_REPORT = "whosCloseActionReport",
  BRONZE_GROUP_TRACKING = "bronzeGroupTracking",
  RENEWALS = "renewalsActionReport",
  NON_BONUSABLE_BV_ACTION_REPORT = "nonBonusableBvActionReport",
  MBI = "mbi",
  ACADEMY = "academy",
  PERFORMANCE_DASHBOARD = "performanceDashboard",
  STRONG_START_INCENTIVE = "strongStartIncentive",
  BRONZE_INCENTIVES = "bronzeIncentives",
  QC_GROWTH = "qcg",
  VISION_BONUS = "visionBonus",
}

export enum ROUTE_PATHS {
  ACADEMY = "/academy",
  PERFORMANCE_BONUS = "/performance-bonus",
  EARNINGS_CALCULATOR = "/calculator.html",
  CSI = "/csi",
  CML = "/cml.html",
  CUSTOMER_OVERVIEW = "/customer/overview",
  LOS = "/rwlos.html",
  EDLOS = "/edlos",
  EDLOS_DOWNLINE = "/$__los-ed/downline-los",
  ACTION_REPORTS = "/action-reports.html",
  WATCHLIST = "/watchlist.html",
  PPE = "/performance-plus-elite",
  MONTHLY_PGG = "/personal-growth-monthly",
  ANNUAL_PGG = "/personal-growth",
  PGG_GCR = "/personal-growth-gcr",
  FGG = "/frontline-growth",
  TWO_TIME_CASH_INCENTIVE = "/ttci",
  FEEDBACK = "/provide-feedback",
  LEARN_ABOUT_CPP = "/learn-about-cpp",
  LOG_OUT = "/log-out",
  HAT = "/hat",
  HAT_ABO_TRACKING = "/$__hat/abo-tracking",
  HAT_QC_DETAILS = "/$__hat/leg-qc-details",
  FAA = "/faa",
  LEADERSHIP_SEMINAR = "/leadership-seminar",
  CBR = "/cbr-action-report.html",
  CVR = "/cvr-action-report.html",
  IBO_ONBOARDING = "/ibo-onboarding-action-report.html",
  BIRTHDAYS_ACTION_REPORT = "/birthdays-action-report.html",
  WHOS_NEW_ACTION_REPORT = "/whos-new-action-report.html",
  WHOS_CLOSE_ACTION_REPORT = "/whos-close-action-report.html",
  BRONZE_GROUP_TRACKING = "/bronze-group-tracking",
  NON_BONUSABLE_BV_ACTION_REPORT = "/nbbv-action-report.html",
  RENEWALS = "/renewals-action-report.html",
  MBI = "/mbi",
  MAINTENANCE = "/maintenance",
  PERFORMANCE_DASHBOARD = "/performance-dashboard",
  MONTHLY_PERFORMANCE = "/performance-dashboard#monthly",
  ANNUAL_PERFORMANCE = "/performance-dashboard#annual",
  STRONG_START_INCENTIVE = "/strong-start-incentive",
  BRONZE_INCENTIVES = "/bronze-incentives",
  QC_GROWTH = "/qc-growth",
  VISION_BONUS = "/vision-bonus",
  NEW_PATH_TO_BRONZE = "/bronze-incentives#pathtobronze",
  NEW_BRONZE_FOUNDATION = "/bronze-incentives#bronzefoundation",
  NEW_BRONZE_BUILDER = "/bronze-incentives#bronzebuilder",
}

export const ANNUAL_INCENTIVES_PATHNAMES: string[] = [
  ROUTE_PATHS.ANNUAL_PGG,
  ROUTE_PATHS.PGG_GCR,
  ROUTE_PATHS.FGG,
  ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE,
  ROUTE_PATHS.ANNUAL_PERFORMANCE,
];
