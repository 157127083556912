import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import { IconInfoCircle } from "../../../../icons/IconInfoCircle/IconInfoCircle";
import { getBonusPeriod } from "../../../../util/common";
import LeafTooltip from "../../../LeafTooltip/LeafTooltip";
import "./LeafNewCustomerTag.scss";

type Props = {
  isNewCustomer?: boolean;
  isNewInCurrentMonth?: boolean;
  isNewMigrated?: boolean;
  selectedPeriod: string;
  aboNo: string;
};

const LeafNewCustomerTag = (props: Props) => {
  const { isNewCustomer, isNewInCurrentMonth, isNewMigrated, selectedPeriod, aboNo } = props;
  const { t } = useTranslation(["profile"]);

  const isNewOnClick = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const Tooltips = () =>
    selectedPeriod === getBonusPeriod(new Date()) && isNewInCurrentMonth === false ? (
      <span className="leaf-new-customer-tag__icon" onClick={isNewOnClick}>
        <LeafTooltip
          content={`${t(
            "customerProfile.tags.newLastMonth",
            defaultContent["customerProfile"]["tags"]["newLastMonth"],
          )}`}
          id={`new_${aboNo}`}
          key={`new_${aboNo}`}
          icon={<IconInfoCircle />}
        />
      </span>
    ) : (
      <></>
    );

  if (isNewCustomer === true && isNewMigrated === false) {
    return (
      <span className="leaf-new-customer-tag">
        <Tooltips />
        <span className="leaf-new-customer-tag__tag-name">{`${t(
          "customerProfile.tags.new",
          defaultContent["customerProfile"]["tags"]["new"],
        )}`}</span>
      </span>
    );
  }
  return <></>;
};

export default LeafNewCustomerTag;
