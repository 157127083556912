import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafRecentReturnDateInfo.scss";

type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
};

const LeafRecentReturnDateInfo = (props: Props) => {
  const { date, privacyFlag, show } = props;
  const { t } = useTranslation(["profile"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric", year: "numeric" }} ignoreTimeZone={true} />
  ) : (
    `${t("aboProfile.datesTab.noRecentReturnDate", defaultContent["aboProfile"]["datesTab"]["noRecentReturnDate"])}`
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-return-order">
        <div className="leaf-return-order__container">
          <div className="leaf-return-order__content-title">
            {`${t(
              "aboProfile.datesTab.recentReturnDate",
              defaultContent["aboProfile"]["datesTab"]["recentReturnDate"],
            )}`}
          </div>
          <div className="leaf-return-order__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafRecentReturnDateInfo;
