import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../content/leadershipSeminar";
import "./FQsComparisonTile.scss";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { IconPersonPlus } from "../../../common/icons/IconPersonPlus/IconPersonPlus";
import LeafTileDynamicDivider from "../../../common/leaf/LeafTileDynamicDivider/LeafTileDynamicDivider";

type Props = {
  isLoading: boolean;
  hideBorder: boolean;
  showFqTileByStack: boolean;
  fqStatusesMet: boolean[];
  totalFqs: number;
  met: boolean;
  status: string;
  tracking: string;
  currentYtd: string;
};

export const FQsComparisonTile = ({
  isLoading,
  hideBorder,
  showFqTileByStack,
  fqStatusesMet,
  totalFqs,
  met,
  status,
  tracking,
  currentYtd,
}: Props) => {
  const { t } = useTranslation(["leadershipSeminar"]);

  if (isLoading) {
    return (
      <div className="FQsComparisonTile">
        <LeafTile
          id="FQsComparisonTile"
          isLoading={isLoading}
          hideBorder={hideBorder}
          rectangleLoaderNumColumns={2}
          rectangleLoaderNumRows={2}
          rectangleLoaderHeightPerLine="4rem"
        ></LeafTile>
      </div>
    );
  }

  const renderTotalFQs = (totalFqs: number, fqStatusesMetArray: boolean[]) => {
    const template = [];
    for (let i = 0; i < totalFqs; i++) {
      template.push(
        <IconPersonPlus
          key={i}
          size="lg"
          color={fqStatusesMetArray[i] ? "green" : "gray"}
          id={`FQsComparisonTile__FQ-${i}`}
        />,
      );
    }
    return template;
  };

  const getFQsComparisonTileContainerClass = () => {
    if (showFqTileByStack) {
      return "FQsComparisonTile__container";
    }
    return "FQsComparisonTile__container FQsComparisonTile__container--sideBySideView";
  };

  const getHeadingClass = () => {
    if (showFqTileByStack) {
      return "FQsComparisonTile__heading";
    }
    return "FQsComparisonTile__heading FQsComparisonTile__heading--forceLeftAlign";
  };

  const getDescriptionClass = () => {
    if (showFqTileByStack) {
      return "FQsComparisonTile__requirementDescription";
    }
    return "FQsComparisonTile__requirementDescription FQsComparisonTile__requirementDescription--forceLeftAlign";
  };

  return (
    <div className="FQsComparisonTile">
      <LeafTile circleCheckmarkId="FQsComparisonTile Icon Check Circle" isLoading={isLoading} hideBorder={hideBorder}>
        <div className={getFQsComparisonTileContainerClass()}>
          <div className="FQsComparisonTile__topSection">
            <div className={getHeadingClass()}>
              <div className="FQsComparisonTile__check">
                <IconCheckCircle
                  id="FQsComparisonTile Icon Check Circle"
                  key={0}
                  size="md"
                  solidCheckCircle={met}
                  color={met ? "green" : "gray"}
                />
              </div>
              <h3>{`${t("requirements.newFqs.title", defaultContent.requirements.newFqs["title"], {
                newFqs: totalFqs,
              })}`}</h3>
            </div>
            <div className="FQsComparisonTile__totalFQs">{renderTotalFQs(totalFqs, fqStatusesMet)}</div>
            <div className={getDescriptionClass()}>
              {`${t("requirements.newFqs.description", defaultContent.requirements.newFqs["description"], {
                totalFqs,
              })}`}
            </div>
          </div>
          <div className="FQsComparisonTile__bottomSection">
            <LeafTile
              circleCheckmarkId="FQsComparisonTile Icon Check Circle"
              id="FQsComparisonTile__compareTile"
              backgroundColor="gray"
            >
              <div className="FQsComparisonTile__compareContainer">
                <div className="FQsComparisonTile__compareHeading">
                  {`${t("compareTable.title", defaultContent.compareTable["title"])}`}
                </div>
                <div className="FQsComparisonTile__compareYears">
                  <div className="FQsComparisonTile__compareYearsItem">
                    <span>
                      <Trans>
                        {`${t("compareTable.fqStatus", defaultContent.compareTable.fqStatus, { prevYear: 30 })}`}
                        <span></span>
                        <span></span>
                      </Trans>
                    </span>
                    <span className="FQsComparisonTile__compareYearsItemBottomText">{status}</span>
                  </div>
                  <LeafTileDynamicDivider />
                  <div className="FQsComparisonTile__compareYearsItem">
                    <span>
                      <Trans>
                        {`${t("compareTable.fqTracking", defaultContent.compareTable.fqTracking, { currentYear: 31 })}`}
                        <span></span>
                        <span></span>
                      </Trans>
                    </span>
                    <span className="FQsComparisonTile__compareYearsItemBottomText">{tracking}</span>
                  </div>
                  <LeafTileDynamicDivider />
                  <div className="FQsComparisonTile__compareYearsItem">
                    <span>{`${t("compareTable.currentYtd", defaultContent.compareTable["currentYtd"])}`}</span>
                    <span className="FQsComparisonTile__compareYearsItemBottomText">{currentYtd}</span>
                  </div>
                </div>
              </div>
            </LeafTile>
          </div>
        </div>
      </LeafTile>
    </div>
  );
};
