import { useState } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeIncentives";
import { useAppSelector } from "../../../../store";
import { getCurrentPeriod } from "../../../../common/util/period";
import { IconLightning } from "../../../../common/icons/IconLightning/IconLightning";
import { LeafBannerToast } from "../../../../common/leaf/LeafBannerToast/LeafBannerToast";
import "./NewPYBanner.scss";

export const BronzeBannerNewPyToast = () => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { configuration } = useAppSelector((state) => state.boot);
  const bannerConfig = configuration?.newPyDataBanner?.bronzeIncentives;
  const show = bannerConfig && bannerConfig.enable && Number(getCurrentPeriod()) >= bannerConfig.startPeriod;

  const [showBanner, setShowBanner] = useState(show);
  const hideBanner = () => {
    setShowBanner(false);
  };

  return (
    <div className="bronze-banner-py-toast">
      <LeafBannerToast theme="dark" show={showBanner} closeFunction={hideBanner}>
        <p className="bronze-banner-py-toast__message">
          <span>
            <IconLightning color="white" />
          </span>
          <span>{`${t("newPYBannerMessage", defaultContent["newPYBannerMessage"])}`}</span>
        </p>
      </LeafBannerToast>
    </div>
  );
};
