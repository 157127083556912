import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import "./LeafVCSTag.scss";

type Props = {
  vcsFlag?: boolean;
};

const LeafVCSTag = (props: Props) => {
  const { vcsFlag } = props;
  const { t } = useTranslation(["profile"]);

  switch (vcsFlag) {
    case true:
      return (
        <span className="tag-vcs">{`${t(
          "customerProfile.tags.verified",
          defaultContent["customerProfile"]["tags"]["verified"],
        )}`}</span>
      );
    case false:
      return (
        <span className="tag-non-vcs">{`${t(
          "customerProfile.tags.nonVerified",
          defaultContent["customerProfile"]["tags"]["nonVerified"],
        )}`}</span>
      );
    default:
      return <></>;
  }
};

export default LeafVCSTag;
