import { useTranslation } from "react-i18next";
import React from "react";
import LeafDateTime from "../../../../../common/leaf/LeafDateTime/LeafDateTime";
import defaultContent from "../../../../../content/customers";
import "./OrdersByPeriod.scss";
import { OrderGroup } from "../../../../../reducers/model/order";
import Order from "./Order/Order";

type Props = {
  orderGroups: OrderGroup[];
  filterApplied: boolean;
  searchApplied: boolean;
  showVCSOrderTag: boolean;
  isCompact?: boolean;
  LoaderView: React.ElementType;
  ErrorView?: React.ElementType;
};

const OrdersByPeriod = ({
  orderGroups,
  filterApplied,
  searchApplied,
  showVCSOrderTag,
  isCompact = false,
  LoaderView,
  ErrorView = () => <></>,
}: Props) => {
  const { t } = useTranslation([
    "customers",
    "order",
    "leafAboName",
    "orderType",
    "orderHistoryNoDataMsg",
    "vcsReasons",
  ]);

  const getOrderCountMessage = (totalOrderCount: number) => {
    if (filterApplied === true || searchApplied === true) {
      return "";
    }

    return totalOrderCount === 0
      ? `${t(
          "orderHistoryTable.noOrders.noOrdersFound",
          defaultContent["orderHistoryTable"]["noOrders"]["noOrdersFound"],
        )}`
      : `${totalOrderCount} ${t("orderHistoryTable.orders", defaultContent["orderHistoryTable"]["orders"])}`;
  };

  return (
    <>
      {orderGroups.map(({ periodDate, totalOrderCount, customerOrderList = [] }) => (
        <div className="order-by-period" key={periodDate}>
          <div className="order-by-period__header">
            <div className="order-by-period__period">
              <LeafDateTime date={periodDate} options={{ month: "long", year: "numeric" }} ignoreTimeZone={true} />
            </div>
            <div className="order-by-period__order-count">{getOrderCountMessage(totalOrderCount)}</div>
          </div>

          <div className="order-by-period__content">
            {customerOrderList.map((order, index) => (
              <Order
                order={order}
                isCompact={isCompact}
                key={index}
                orderIndex={index}
                showVCSOrderTag={showVCSOrderTag}
              />
            ))}
          </div>
        </div>
      ))}
      <LoaderView />
      <ErrorView />
    </>
  );
};

export default OrdersByPeriod;
