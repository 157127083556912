import { ReactElement, useLayoutEffect, useMemo } from "react";
import { PromiseFn, useAsync } from "react-async";
import { PersistPartial } from "redux-persist/es/persistReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { initAccBoot } from "../../reducers/model/boot";
import { BootType, setShellConfig, setBootLoading } from "../../reducers/boot";
import { ShellConfigData } from "../../services/ShellConfig/ShellConfigDataLayer";
import { ANNUAL_INCENTIVES_PATHNAMES, ROUTE_PATHS } from "../../common/enums/routing-enums";
import "./ComponentLoader.scss";
import { authRoutePaths } from "../../routing/AuthRouting";
import { createMenuConfiguration, getDefaultHomePage } from "../../common/util/navigationUtil";
import { CUSTOMER_OVERVIEW_ROUTE, CUSTOMER_ROUTE_PATHS } from "../../routing/CustomersRouting";
import { getAllErrorRoutes } from "../../routing/ErrorRouting";
import { MBI_ROUTE_PATHS } from "../../routing/MBIRouting";
import { setNavigatedByPeriod } from "../../reducers/period";
import { ERROR_TYPE, ErrorPage } from "../ErrorPage/ErrorPage";
import {
  setAvailableRouteCheckComplete,
  setCurrentPageAvailable,
  setShowErrorPage,
} from "../../reducers/componentLoader";
import { ANA_MARKETS } from "../../common/constants/market-constants";
import { Business } from "../../services/BusinessSelector/businessSelectorAPI.types";

const setBoot: PromiseFn<BootType & PersistPartial> = async ({ boot }) => {
  return initAccBoot(boot);
};

// "if the page is not available, check if we should show the date range error message or navigate to the monthly dashboard"
// Special check for ABOs where the primary is ANA. If true the date range error page should display
// when navigating to a page that is not available.
// For NON ANA markets when the same conditions are met, the ABO should be redirected to the Monthly Dashboard.
const primaryAboIsANA = (businessSelectorData: Business[]) => {
  const primaryAbo = businessSelectorData.find((d: Business) => d.isPrimary);
  if (primaryAbo) {
    return ANA_MARKETS.includes(primaryAbo.isoCountry);
  }
  return false;
};

const ShellConfigLoader = ({ children }: { children: ReactElement }) => {
  const { selectedPeriod, navigatedByPeriodChange } = useAppSelector((state) => state.period);
  const { user, configuration, isBootLoading, shellConfig } = useAppSelector((state) => state.boot);
  const { availableRouteCheckComplete, showErrorPage, isCurrentPageAvailable } = useAppSelector(
    (state) => state.componentLoader,
  );
  const { businessSelectorData } = useAppSelector((state) => state.businessSelectorData);
  const { pathname, hash } = useLocation();

  const isAnnualPath = ANNUAL_INCENTIVES_PATHNAMES.includes(pathname) || hash === "#annual";

  const { data, isLoading: isShellConfigLoading } = ShellConfigData({ pathname });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // 1. once the shellConfig is loaded, create the menu config to populate dropdown menus on the header
  useLayoutEffect(() => {
    dispatch(setAvailableRouteCheckComplete(false));
    if (isAnnualPath) {
      dispatch(setBootLoading(true));
    }
    if (!isShellConfigLoading && data) {
      dispatch(
        setShellConfig({
          ...data,
          menuConfig: createMenuConfiguration({ user, configuration }, data),
        }),
      );
      dispatch(setBootLoading(false));
    }
  }, [isShellConfigLoading, data, user, isAnnualPath, dispatch, selectedPeriod, configuration]);

  // 2. after the menu config is created, check if the ABO is able to see the current page
  useMemo(() => {
    const ignoreRouteFromBusinessCheck = [
      ...authRoutePaths,
      ...getAllErrorRoutes(),
      ROUTE_PATHS.ACADEMY,
      ROUTE_PATHS.MONTHLY_PERFORMANCE,
      ROUTE_PATHS.LOG_OUT,
      ROUTE_PATHS.LEARN_ABOUT_CPP,
      ROUTE_PATHS.FEEDBACK,
    ];

    if (
      shellConfig.menuConfig &&
      shellConfig.menuConfig.length > 0 &&
      !isBootLoading &&
      !ignoreRouteFromBusinessCheck.includes(pathname)
    ) {
      const availableRoutesForCurrentBusiness = shellConfig.menuConfig
        .flatMap((dropdown: any) => dropdown.items)
        .map((link: any) => link.path);

      // eslint-disable-next-line no-unsafe-optional-chaining
      const { reports = [] } = configuration?.actionReports;
      const { navigation } = configuration || {};
      const { showActionReports = false, showMBI = false } = navigation || {};

      if (showActionReports) {
        reports.forEach((report) => availableRoutesForCurrentBusiness.push(report.url));
      }

      if (showMBI) {
        availableRoutesForCurrentBusiness.push(...MBI_ROUTE_PATHS, ROUTE_PATHS.MBI);
      }

      if (availableRoutesForCurrentBusiness.includes(CUSTOMER_OVERVIEW_ROUTE)) {
        availableRoutesForCurrentBusiness.push(...CUSTOMER_ROUTE_PATHS, ROUTE_PATHS.CML);
      }

      dispatch(
        setCurrentPageAvailable(
          hash === "#annual"
            ? shellConfig.shouldShowAnnualPerformance
            : availableRoutesForCurrentBusiness.includes(pathname),
        ),
      );
    }
  }, [configuration, dispatch, isBootLoading, pathname, hash, shellConfig.menuConfig]);

  // 3. if the page is not available,
  // check if we should show the date range error message or navigate to the monthly dashboard
  useMemo(() => {
    if (availableRouteCheckComplete) {
      if (navigatedByPeriodChange && primaryAboIsANA(businessSelectorData)) {
        dispatch(setShowErrorPage(!isCurrentPageAvailable));
        return;
      } else {
        if (!isCurrentPageAvailable) {
          navigate(getDefaultHomePage(configuration.cppModHomePage));
        }
      }
      // reset flags
      dispatch(setAvailableRouteCheckComplete(false));
      dispatch(setNavigatedByPeriod(false));
    }
  }, [availableRouteCheckComplete, isCurrentPageAvailable, dispatch, navigate, configuration, navigatedByPeriodChange]);

  return (isShellConfigLoading && isBootLoading && !authRoutePaths.includes(pathname)) ||
    (isShellConfigLoading && ANNUAL_INCENTIVES_PATHNAMES.includes(pathname)) ? (
    <div>
      <p className="componentLoader__title"></p>
      <p className="componentLoader__row"></p>
      <p className="componentLoader__row"></p>
      <p className="componentLoader__row"></p>
    </div>
  ) : (
    <>{showErrorPage ? <ErrorPage errorType={ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD} /> : children}</>
  );
};

export const ComponentLoader = ({
  children,
  isComponentRoute,
  period,
  route,
}: {
  children: ReactElement;
  isComponentRoute: boolean;
  period: string;
  route: string;
}) => {
  const boot = useAppSelector(({ boot }) => boot);
  const { isPending } = useAsync({ promiseFn: setBoot, boot });

  return <ShellConfigLoader>{isPending && isComponentRoute ? <></> : <>{children}</>}</ShellConfigLoader>;
};
