const content: { [key: string]: any } = {
  pageTitle: {
    title: "Leadership Seminar",
  },
  bannerTile: {
    earned: "Great job! You have qualified for the {tripName}.",
    automaticallyApproved: "Congratulations! You’re Qualified for the {tripName}!",
    tracking: "Keep going so you can join the {tripName}!",
    notTracking: "You are not currently tracking to join the {tripName}.",
    description: "For final confirmation of the trip qualification status, please consult with your local sales team.",
    notEligible: "You are not currently eligible for the {tripName}.",
  },
  progressTile: {
    title: "Track your progress",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of requirements.",
    subtext:
      "Founders Platinum through Founders Emerald ABOs must grow by <1>{fqs}</1> FQs compared to the previous fiscal year. Founders Platinum & Founders Ruby must also generate a minimum of <1>{gpv}</1> Group PV monthly. As an alternative for the first timers, Founders Platinum & Founders Ruby can generate a total of <1>{gpv2}</1> Group PV in the fiscal year <1>{performanceYear}</1>. Qualified Diamond leaders are invited automatically. Click 'Learn More' for more information.",
    description: "You’ve met <1>{current} of {needed}</1> requirements to earn this Non-Cash Award (NCA).",
    monthlyRequirements: "Monthly Requirements",
    annualRequirements: "Annual Requirements",
    thisRequirementMustBeMet: "This requirement must be met.",
    oneOfTheseMustBeMet: "One of these two requirements must be met.",
    monthlyGpvStatus: "Monthly GPV Status",
    optionOne: "Option 1 – Meet these three requirements",
    optionTwo: "Option 2 – Meet these two requirements",
    personal: {
      title: "{volume} Annual PPV",
      description: "",
    },
    ruby: {
      title: "{volumeRuby} Ruby PV or {volumeLegs} with 1 or more qualified legs",
      description: "",
    },
    basisCheckForFoundersPlatinumWithEq: {
      title: "Qualified Founders Platinum Pin or Above",
      description: "Must qualify Founders Platinum to earn this incentive.",
    },
    basisCheckForFoundersPlatinumAward: {
      title: "Qualified Founders Platinum Award or Above",
      description: "",
    },
    emeraldBonusRecipient: {
      title: "Qualified Emerald Bonus Recipient",
      description: "",
    },
    ytdVcsPercentageOfPpv: {
      title: "60% Annual VCS of PPV",
      description: "",
    },
    annualGpv: {
      title: "{volumeAnnualGpv} Annual GPV",
      description: "",
    },
    monthlyGpv: {
      title: "{volumeMonthlyGpv} Monthly GPV",
      description: "",
    },
    newFqs: {
      title: "+{newFqs} New FQs",
      description:
        "To meet this requirement, you must grow your FQ count by {totalFqs} compared to the previous performance year.",
    },
    basisCheckForPggKickerWithEq: {
      title: "Qualified PGG Kicker Award",
      description: "",
    },
    basisCheckForEmeraldParticipantWithEq: {
      title: "Qualified Emerald Bonus Recipient",
      description: "Must qualify Emerald Bonus Recipient to earn this incentive.",
    },
  },
  compareTable: {
    title: "Compare with Previous Year:",
    fqStatus: "<1>PY{prevYear}</1> <2>Status</2>",
    fqTracking: "<1>PY{currentYear}</1> <2>Tracking</2>",
    requiredFqs: "Required FQs",
    currentYtd: "Current YTD",
  },
  legend: {
    earned: "Earned",
    potential: "Potential",
    notEarned: "Not Earned",
    unavailable: "Unavailable",
  },
  pqRequirementsTitle: "{achievedPqs} out of {totalPqs} PQs",
  pqRequirementsDescription: "",
  tagMetRequirement: "You’ve met this requirement.",
  additionalVolumeTag: "Additional {volume} Needed",
  onTrackTag: "On Track",
  achievedTag: "Achieved",
  notTracking: "Not Tracking",
  currentFqs: "{fqs} FQs",
  previousFqs: "{fqs} FQs",
  pyLabel: "PY{performanceYear}",
  average: "Average",
  abo: "ABO",
  target: "Target: {target}",
} as const;

export default content;
