import { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "../../icons/IconInfoCircle/IconInfoCircle";
import fggContent from "../../../content/fggMultiplierDetails";
import { LeafModal } from "../../leaf/LeafModal/LeafModal";
import "./MultiplierDetail.scss";
interface ButtonInfo {
  callback: Function;
  ref: RefObject<HTMLButtonElement>;
  text: string;
  icon: JSX.Element;
}

interface Props {
  title: string;
  subtitle: string;
  description?: string;
  buttonInfo: ButtonInfo | null;
  isMoreInfoAvailable?: boolean;
}

export const MultiplierDetail = ({ title, subtitle, description, buttonInfo, isMoreInfoAvailable }: Props) => {
  const getButton = () => {
    if (buttonInfo) {
      return (
        <button
          aria-label="MultiplierDetail-Table-Icon-Button"
          className="MultiplierDetail__button"
          onClick={() => buttonInfo.callback()}
          ref={buttonInfo.ref}
        >
          <span className="MultiplierDetail__button-text">{buttonInfo.text}</span>
          <span className="MultiplierDetail__button-icon">{buttonInfo.icon}</span>
        </button>
      );
    }
    return <></>;
  };

  const { t } = useTranslation(["fggMultiplierDetails"]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleShowInfoModal = () => {
    setShowInfoModal(true);
  };

  const modalTitle = t("title", fggContent["title"]).toString();
  const modalDescription = t("description", fggContent["description"]).toString();

  return (
    <section className="MultiplierDetail">
      <div className="MultiplierDetail__title">
        {title}
        {isMoreInfoAvailable && (
          <button
            aria-label={`${t("moreInfo", fggContent["moreInfo"])}`}
            className="MultiplierDetail__title__info-button"
            onClick={handleShowInfoModal}
          >
            <IconInfoCircle />
          </button>
        )}
      </div>
      <div className="MultiplierDetail__subtitle">{subtitle}</div>
      <div className="MultiplierDetail__description">{description || ""}</div>
      {getButton()}
      <LeafModal
        open={showInfoModal}
        onCloseCallbackFn={() => setShowInfoModal(false)}
        title={modalTitle}
        description={modalDescription}
      />
    </section>
  );
};
