import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/bronzeIncentives";
import { VolumeRequirementTile } from "../../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  formatted: {
    actual: string;
    target: string;
    short: string;
  };
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementPersonalOrderPV = (props: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);

  const {
    met = false,
    required = false,
    actual = 0,
    target = 0,
    short = 0,
    formatted: { actual: formattedActual = "", target: formattedTarget = "", short: formattedShort = "" } = {},
    isFetching,
  } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VolumeRequirementTile
      title={`${t("bronzeRequirements.pcpvTitle", defaultContent["bronzeRequirements"]["pcpvTitle"], {
        PCPV: formattedTarget,
      })}`}
      additionalNeeded={formattedShort}
      met={met}
      isLoading={isFetching}
      currentAmount={actual}
      targetAmount={target}
      circleCheckmarkId={"volumeRequirementTile__pcpv"}
      volumeType={"personalTransaction"}
      showLabelsOnly={true}
      labels={[formattedActual, formattedShort]}
    />
  );
};

export default BronzeRequirementPersonalOrderPV;
