import { useEffect } from "react";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { useGetBronzeOverviewQuery } from "../../../services/BronzeIncentives/bronzeIncentivesAPI";
import { updateOverviewDetails } from "../../../reducers/bronzeIncentive";
import { storedLocale } from "../../impersonation/util";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import BronzeIncentivesLoader from "../BronzeCommon/BronzeIncentivesLoader/BronzeIncentivesLoader";
import BFIBannerTile from "./BFIBannerTile/BFIBannerTile";
import BFIIncentiveProgress from "./BFIIncentiveProgress/BFIIncentiveProgress";
import BFIEligibilityBannerTile from "./BFIEligibilityBannerTile/BFIEligibilityBannerTile";
import BFIRequirements from "./BFIRequirements/BFIRequirements";
import BFIMetric from "./BFIMetric/BFIMetric";
import BFIConsistencyPaymentMetric from "./BFIConsistencyPaymentMetric/BFIConsistencyPaymentMetric";
import "./BronzeFoundation.scss";

const BronzeFoundation = () => {
  const boot = useAppSelector(({ boot }) => boot);
  const { configuration } = useAppSelector((state) => state.boot);
  const locale = storedLocale();
  const dispatch = useAppDispatch();
  const affAbo = getLoginAffAbo({ boot });
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};
  const { isStandardRetailEnhancementCountry = false } = configuration?.corePlus || {};
  const primaryAwardCode = isoCountryCode === "MN" ? AWARD_CODES.MN_BRONZE_FOUNDATION : AWARD_CODES.BRONZE_FOUNDATION;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const { data, isFetching } = useGetBronzeOverviewQuery({
    affAbo,
    period: selectedPeriod,
    awardCode: primaryAwardCode,
    locale,
    isoCountryCode,
    isoCurrencyCode,
    fixedPayment: AWARD_CODES.BRONZE_FOUNDATION_FIXED_PAYMENT,
    restart1: AWARD_CODES.RESTART_BRONZE_FOUNDATION,
    restart2: AWARD_CODES.RESTART_TWO_BRONZE_FOUNDATION,
    isStandarRetailEnhancementCountry: isStandardRetailEnhancementCountry,
  });

  if (isFetching === false && data) {
    const { status, displayRules, eligibility } = data;
    const { awardCode, isFixedPayment, fixedPaymentAwardCode } = status;
    const { displayBannerOnly, displayEligibleBannerOnly, showAllBannerTiles } = displayRules;

    dispatch(updateOverviewDetails({ ...status, showEligibilitySliderFlag: false, eligibility, isFetching: false }));

    return (
      <div
        className={`BronzeFoundation BronzeFoundation__${displayBannerOnly ? "banner-only" : ""} BronzeFoundation__${
          displayEligibleBannerOnly ? "eligible-banner-only" : ""
        }`}
      >
        <div className="BronzeFoundation__overview">
          <div className={`BronzeFoundation__overview__banner`}>
            <BFIBannerTile
              isCompactView={true}
              show={displayEligibleBannerOnly === false}
              data={data}
              isFetching={isFetching}
            />
          </div>
          <div className={`BronzeFoundation__overview__tracking`}>
            <BFIIncentiveProgress data={data} show={showAllBannerTiles} isFetching={isFetching} />
          </div>
          <div className={`BronzeFoundation__overview__eligible`}>
            <BFIEligibilityBannerTile data={data} show={displayBannerOnly === false} isFetching={isFetching} />
          </div>
        </div>
        <div className="BronzeFoundation__incentive-calculation">
          <BFIMetric data={data} show={showAllBannerTiles} isFetching={isFetching} />
          <BFIConsistencyPaymentMetric
            isFixedPayment={isFixedPayment}
            show={showAllBannerTiles}
            data={data}
            isFetching={isFetching}
          />
        </div>
        <div className="BronzeFoundation__requirements">
          <BFIRequirements awardCode={awardCode} show={showAllBannerTiles} data={data} isFetching={isFetching} />
        </div>
      </div>
    );
  }
  return <BronzeIncentivesLoader />;
};

export default BronzeFoundation;
