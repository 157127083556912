import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../content/bronzeGroupTracking";
import "./BGTInfoModal.scss";
import LeafPanel, { ModalProps } from "../../../common/leaf/LeafPanel/LeafPanel";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { useAppSelector } from "../../../store";
import { bgtInfoModalPageLoadActionTrack } from "../../../common/tealium/BronzeGroupTracking/BGTInfoModal";

const BGTInfoModal = ({ show, onCloseClick, title }: ModalProps) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { restartIsActive } = useAppSelector(({ bronzeGroupTracking }) => bronzeGroupTracking);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  if (show) bgtInfoModalPageLoadActionTrack({ period: selectedPeriod });

  return (
    <div className="BGTinfoDrawer">
      <LeafPanel id={"BGT-info-modal"} {...{ show, onCloseClick, title }}>
        <section className="BGTinfoDrawer__wrapper">
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t("overviewTabContent.closeToBronzeTitle", defaultContent["overviewTabContent"]["closeToBronzeTitle"])}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.closeToBronzeDrawerDesc",
                  defaultContent["informationDrawerContent"]["closeToBronzeDrawerDesc"],
                  {
                    bfPerfBonus: "6",
                    bfLegs: "2",
                    bfAchieved: "3",
                    bbPerfBonus: "9",
                    bbLegs: "2",
                    bbAchieved: "6",
                  },
                )}
                <span>{/* bfPerfBonus */}</span>
                <span>{/* bfLegs */}</span>
                <span>{/* bfAchieved */}</span>
                <span>{/* bbPerfBonus */}</span>
                <span>{/* bbLegs */}</span>
                <span>{/* bbAchieved */}</span>
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t("overviewTabContent.newToBronzeTitle", defaultContent["overviewTabContent"]["newToBronzeTitle"])}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.newToBronzeDrawerDesc",
                  defaultContent["informationDrawerContent"]["newToBronzeDrawerDesc"],
                )}
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>{t("graduated", defaultContent["graduated"])}</Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.graduatedDrawerDesc",
                  defaultContent["informationDrawerContent"]["graduatedDrawerDesc"],
                  {
                    monthsAchieved: "12",
                  },
                )}
                <span>{/* monthsAchieved */}</span>
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t(
                  "informationDrawerContent.trackingBronzeDrawerTitle",
                  defaultContent["informationDrawerContent"]["trackingBronzeDrawerTitle"],
                )}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.trackingBronzeDrawerDesc",
                  defaultContent["informationDrawerContent"]["trackingBronzeDrawerDesc"],
                  {
                    bfCurrentLevelPercent: "6",
                    bfLegPercent: "3",
                    bbCurrentLevelPercent: "12",
                    bbLegPercent: "6",
                  },
                )}
                <span>{/* bfCurrentLevelPercent */}</span>
                <span>{/* bfLegPercent */}</span>
                <span>{/* bbCurrentLevelPercent */}</span>
                <span>{/* bbLegPercent */}</span>
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t(
                  "informationDrawerContent.preWindowDrawerTitle",
                  defaultContent["informationDrawerContent"]["preWindowDrawerTitle"],
                )}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.preWindowDrawerDesc",
                  defaultContent["informationDrawerContent"]["preWindowDrawerDesc"],
                )}
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t(
                  "informationDrawerContent.inWindowDrawerTitle",
                  defaultContent["informationDrawerContent"]["inWindowDrawerTitle"],
                )}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.inWindowDrawerDesc",
                  defaultContent["informationDrawerContent"]["inWindowDrawerDesc"],
                  {
                    monthMaximum: "12",
                  },
                )}
                <span>{/* monthMaximum */}</span>
              </Trans>
            </p>
          </section>
          <section hidden={!restartIsActive}>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t(
                  "informationDrawerContent.restartedDrawerTitle",
                  defaultContent["informationDrawerContent"]["restartedDrawerTitle"],
                )}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.restartedDrawerDesc",
                  defaultContent["informationDrawerContent"]["restartedDrawerDesc"],
                  {
                    additionalPayments: "12",
                    newMonthWindow: "18",
                  },
                )}
                <span>{/* additionalPayments */}</span>
                <span>{/* newMonthWindow */}</span>
              </Trans>
            </p>
          </section>
          <section>
            <h4 className="BGTinfoDrawer__title">
              <Trans>
                {t(
                  "informationDrawerContent.requirementsDrawerTitle",
                  defaultContent["informationDrawerContent"]["requirementsDrawerTitle"],
                )}
              </Trans>
            </h4>
            <p className="BGTinfoDrawer__desc">
              <Trans>
                {t(
                  "informationDrawerContent.requirementsDrawerDescOne",
                  defaultContent["informationDrawerContent"]["requirementsDrawerDescOne"],
                )}
              </Trans>
              <span className="BGTinfoDrawer__iconCircle">
                <IconCheckCircle id="BGTinfoDrawer Icon Check Circle" color="green" size="sm" />
              </span>
              <Trans>
                {t(
                  "informationDrawerContent.requirementsDrawerDescTwo",
                  defaultContent["informationDrawerContent"]["requirementsDrawerDescTwo"],
                )}
              </Trans>
            </p>
          </section>
        </section>
      </LeafPanel>
    </div>
  );
};

export default BGTInfoModal;
