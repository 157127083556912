import { useTranslation } from "react-i18next";
import { memo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import defaultContent from "../../../content/keyMetricsTile";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import "./KeyMetricsTile.scss";
import { KpiSelector } from "../../../common/components/KpiSelector/KpiSelector";
import { IconGear } from "../../../common/icons/IconGear/IconGear";
import { KeyMetrics } from "../../../common/interfaces/config";
import TEALIUM from "../../../common/enums/tealium-enums";
import { useUpdateSavedKpiPreferencesMutation } from "../../../services/KPIPreferences/savedKpiPreferencesAPI";
import { addParams } from "../../util/addParams";
import { setSavedKpiPreferencesData } from "../../../reducers/savedKpiPreferences";
import {
  getSavedKpiPreferencesForView,
  updateSavedKpiPreferencesForView,
} from "../../../common/util/savedKpiPrefrencesUtil";
import { LeafIconButton } from "../../leaf/LeafIconButton/LeafIconButton";
import KpiTiles from "./KpiTiles/KpiTiles";

type KeyMetricsAPIData = {
  keyMetricsData: any;
  isLoading: boolean;
  errorStatus: boolean;
  refetch: Function;
};

type Props = {
  keyMetricsAPIData: KeyMetricsAPIData;
  keyMetricsConfig: KeyMetrics;
  tealiumDashboardName: "monthly" | "annual";
};

const KeyMetricsTile = memo(({ keyMetricsConfig, tealiumDashboardName, keyMetricsAPIData }: Props) => {
  const { t } = useTranslation(["keyMetricsTile"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const [showKpiSelectorModal, setShowKpiSelectorModal] = useState(false);
  const gearButtonRef = useRef<HTMLButtonElement>(null);
  const prevOpenRef = useRef(false);
  const persona = useAppSelector(({ boot }) => boot.shellConfig.persona);
  const { configuration, user } = useAppSelector((state) => state.boot);
  const { savedKpiPreferencesData } = useAppSelector((state) => state.savedKpiPreferencesData);
  const [updateSavedKpiPreferences] = useUpdateSavedKpiPreferencesMutation();
  const dispatch = useAppDispatch();

  const {
    viewName,
    viewDisplayName,
    defaultKpis,
    availableKpis,
    minSelectionsAllowed,
    maxSelectionsAllowed,
    showPVBreakdown,
  } = keyMetricsConfig;
  const [kpisToShow, setKpisToShow] = useState(defaultKpis[`${persona}`]?.length > 0 ? defaultKpis[`${persona}`] : []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const kpiSelectorModalParam = queryParams.get("kpiSelectorModal") === "true";

  useEffect(() => {
    if (kpiSelectorModalParam && !showKpiSelectorModal) {
      setShowKpiSelectorModal(false);
      addParams("kpiSelectorModal", "false", false, true);
    }
  }, [kpiSelectorModalParam, showKpiSelectorModal]);

  useEffect(() => {
    setKpisToShow(defaultKpis[`${persona}`]?.length > 0 ? defaultKpis[`${persona}`] : []);
  }, [persona, defaultKpis]);

  // Below useEffect is used to focus back to the element after modal is closed for desktop as we are not using the leafModalPanel for kpiSelector desktop.
  useEffect(() => {
    if (!!prevOpenRef.current && !showKpiSelectorModal && gearButtonRef && gearButtonRef.current) {
      gearButtonRef.current.focus();
    }
    prevOpenRef.current = showKpiSelectorModal;
  }, [showKpiSelectorModal]);

  const showSelectorModal = () => {
    setShowKpiSelectorModal(!showKpiSelectorModal);
    addParams("kpiSelectorModal", (!showKpiSelectorModal).toString(), !showKpiSelectorModal, showKpiSelectorModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      `${tealiumDashboardName}_dashboard`,
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "customize_kpi",
    );
  };

  const getSelectedKpis = () =>
    getSavedKpiPreferencesForView(viewName, savedKpiPreferencesData).length
      ? getSavedKpiPreferencesForView(viewName, savedKpiPreferencesData).filter((kpi) => availableKpis.includes(kpi))
      : kpisToShow;

  const { keyMetricsData, isLoading, errorStatus, refetch: refetchKeyMetricsData } = keyMetricsAPIData;

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile
          clickFunction={refetchKeyMetricsData}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Key_Metrics_Tile"
        />
      </LeafTile>
    );
  }

  const submitSelectedKpis = async (selectedKpis: string[]) => {
    setKpisToShow(selectedKpis);
    showSelectorModal();
    const { preferenceDetails, myBizPreferenceObj } = updateSavedKpiPreferencesForView(
      viewName,
      viewDisplayName,
      availableKpis,
      selectedKpis,
      savedKpiPreferencesData,
      defaultContent,
      t,
    );

    dispatch(setSavedKpiPreferencesData(preferenceDetails));

    try {
      updateSavedKpiPreferences({
        baseUrl: configuration.mdmsGeoCacheDomain,
        abo: user.abo,
        aff: user.aff,
        api_key: configuration.magicApiKey,
        body: myBizPreferenceObj,
        urlObj: {
          authorization: `${user.access_token}`,
          enableV4Apis: configuration.enableV4Apis,
        },
      });
    } catch (error) {
      console.error();
    }
  };

  return (
    <LeafTile hideBorder={true} isLoading={false}>
      <div onClick={showSelectorModal} className={`${showKpiSelectorModal ? "KeyMetricsTile__backdrop" : ""}`} />
      <div className={"KeyMetricsTile"}>
        <div className={"KeyMetricsTile__header"}>
          <h2 className="KeyMetricsTile__title">{t("keyMetrics", defaultContent["keyMetrics"])}</h2>
          <LeafIconButton
            Icon={<IconGear color={showKpiSelectorModal ? "blue" : "gray"} />}
            HoverIcon={<IconGear color="blue" />}
            aria-label={`${t("keyMetricsMenuArialLabel", defaultContent["keyMetricsMenuArialLabel"])}`}
            className="KeyMetricsTile__gear"
            onClick={showSelectorModal}
            id="kpiSelectorButton"
          />
        </div>
        <KpiSelector
          show={showKpiSelectorModal}
          availableKpis={availableKpis}
          selectedKpis={getSelectedKpis()}
          onCloseCallbackFn={showSelectorModal}
          minSelectionsAllowed={minSelectionsAllowed}
          maxSelectionsAllowed={maxSelectionsAllowed}
          onSubmitFn={(selectedKpis: string[]) => {
            submitSelectedKpis(selectedKpis);
          }}
        />
        <KpiTiles
          keyMetricsData={keyMetricsData}
          isLoading={isLoading}
          kpis={getSelectedKpis()}
          showPVBreakdown={showPVBreakdown}
        />
      </div>
    </LeafTile>
  );
});

export default KeyMetricsTile;
