import { useTranslation } from "react-i18next";
import { ReactEventHandler } from "react";
import LeafProfileLoader from "../../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import Order from "../../../../../../Customers/OrderHistory/OrderHistoryTable/OrdersByPeriod/Order/Order";
import defaultContent from "../../../../../../../content/profile";
import { OrderResponse } from "../../../../../../../services/Customer/CustomerOrderList/customerOrderListAPI.types";
import { useAppSelector } from "../../../../../../../store";

type Props = {
  viewAllOnClick: ReactEventHandler;
  order?: OrderResponse;
  isFetching: boolean;
};

const RecentOrderListView = (props: Props) => {
  const { t } = useTranslation(["profile"]);
  const { viewAllOnClick, order, isFetching } = props;
  const showVCSOrderTag = useAppSelector((state) => state.boot?.configuration?.globalCML?.orderList?.showVCSOrderTag);

  if (isFetching === false && order) {
    const { customerOrderList } = order;

    return (
      <>
        {customerOrderList.length > 0 && (
          <>
            <div className="overview-tab__order-header">
              <div className="overview-tab__recent-order">
                {`${t(
                  "customerProfile.overviewTab.mostRecentOrder",
                  defaultContent["customerProfile"]["overviewTab"]["mostRecentOrder"],
                )}`}
              </div>
              <div className="overview-tab__view-all" onClick={viewAllOnClick}>
                {`${t(
                  "customerProfile.overviewTab.viewAll",
                  defaultContent["customerProfile"]["overviewTab"]["viewAll"],
                )}`}
              </div>
            </div>
            {customerOrderList.map((order, index) => (
              <Order
                order={order}
                isCompact={true}
                key={order.orderNumber}
                orderIndex={index}
                showVCSOrderTag={showVCSOrderTag}
              />
            ))}
          </>
        )}
      </>
    );
  }
  return <LeafProfileLoader row={1} card={true} isFetching={isFetching} />;
};

export default RecentOrderListView;
