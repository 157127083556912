import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeIncentives";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BFIMetric.scss";

type Props = {
  data: BronzeOverviewResponse;
  show?: boolean;
  isFetching: boolean;
};

const BFIMetric = ({ data, show = true, isFetching = true }: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);

  const { incomeData } = data;
  const { basis, multiplier, bonus } = incomeData || {};

  if (show === false) return <></>;

  return (
    <div className="bfi-metric">
      <div className="bfi-metric__content">
        <h2 className="bfi-metric__header">{`${t("metric.header", defaultContent["metric"]["header"])}`}</h2>
        <div className="bfi-metric__incentive-calculation">
          <IncentiveCalculation
            isLoading={isFetching}
            base={{
              title: `${t("metric.performanceBonus", defaultContent["metric"]["performanceBonus"])}`,
              amount: basis?.amountCurrency || "0",
            }}
            multiplier={{
              title: `${t("metric.bfiMultiplier", defaultContent["metric"]["bfiMultiplier"])}`,
              amount: `${multiplier}%`,
              symbolIcon: <IconMultiply />,
            }}
            total={{
              title: `${t("metric.currentTotal", defaultContent["metric"]["currentTotal"])}`,
              amount: bonus?.amountCurrency || "0",
              symbolIcon: <IconEquals />,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default BFIMetric;
