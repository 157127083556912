import { useState } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/profile";
import LeafLinkButton from "../../../common/leaf/LeafLinkButton/LeafLinkButton";
import "./ViewNote.scss";
import { IconArrowLink } from "../../../common/icons/IconArrowLink/IconArrowLink";
import { readMoreNoteClickTrack } from "../../../common/tealium/CustomerProfile/Note/NoteTrack";

type Props = {
  noteId?: string;
  content?: string;
  maxLength?: number;
};

const ViewNote = (props: Props) => {
  const { t } = useTranslation(["profile"]);

  const readMoreText = `${t("customerProfile.note.readMore", defaultContent["customerProfile"]["note"]["readMore"])}`;
  const viewLessText = `${t("customerProfile.note.viewLess", defaultContent["customerProfile"]["note"]["viewLess"])}`;

  const [readMore, setReadMore] = useState(false);
  const { noteId, content, maxLength = 150 } = props;

  const readMoreButtonOnClick = () => {
    setReadMore(true);
    readMoreNoteClickTrack();
  };

  const viewLessButtonOnClick = () => {
    setReadMore(false);
  };

  if (noteId && content) {
    const truncatedText = content?.substring(0, maxLength + 1);
    const displayContent = readMore ? content : truncatedText;
    const isExpandable = content.length > maxLength;

    return (
      <div className="viewNote viewNote__content">
        <span className="viewNote__content-text">{displayContent}</span>
        <span className="viewNote__content--collapsed-btn">
          <LeafLinkButton
            onClick={readMoreButtonOnClick}
            label={readMoreText}
            theme="blue"
            fontWeight="bold"
            fontSize="medium"
            show={isExpandable === true && readMore === false}
            underlined={false}
          >
            {". . . " + readMoreText}
          </LeafLinkButton>
        </span>
        <div className="viewNote__content--expanded-viewLess">
          <div className="viewNote__content--expanded-viewLess-text">
            <LeafLinkButton
              onClick={viewLessButtonOnClick}
              label={viewLessText}
              theme="blue"
              fontWeight="bold"
              fontSize="medium"
              underlined={false}
              show={readMore === true}
            >
              {viewLessText}
              <div className="viewNote__content--expanded-viewLess-text-arrow" aria-hidden>
                <IconArrowLink />
              </div>
            </LeafLinkButton>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default ViewNote;
