import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../../content/bronzeIncentives";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/BronzeFoundation/LearnMoreButtonTrack";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BFIBannerTile.scss";

type Props = {
  isCompactView?: boolean;
  show?: boolean;
  data: BronzeOverviewResponse;
  isFetching: boolean;
};

const BFIBannerTile = ({ isCompactView = false, show = true, data, isFetching = true }: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);

  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = useAppSelector(
    (store) => store.boot?.configuration?.dashboardLinks?.learnMore?.bronzeFoundation,
  );

  const { incomeData } = data;

  const {
    status = "",
    consistencyPayment = {
      target: 0,
      actual: 0,
      met: false,
      schedule: [],
      basePayment: {
        amount: 0,
        amountCurrency: "",
      },
      additionalPayment: {
        amount: 0,
        amountCurrency: "",
      },
      totalPayment: {
        amount: 0,
        amountCurrency: "",
      },
    },
  } = incomeData || {};

  if (!isFetching) {
    const bannerMessages = [
      {
        status: [
          QUALIFICATION_STATUS.NOT_QUALIFIED,
          QUALIFICATION_STATUS.ELIGIBLE,
          QUALIFICATION_STATUS.PENDING_ELIGIBLE,
          QUALIFICATION_STATUS.REWORK_DISQUALIFIED,
        ],
        title: `${t("bannerTile.bfi.keepGoing", defaultContent["bannerTile"]["bfi"]["keepGoing"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.NOT_ELIGIBLE],
        title: `${t("bannerTile.bfi.notEligible", defaultContent["bannerTile"]["bfi"]["notEligible"], {
          dateText: getBronzeDisplayDate(selectedPeriod),
        })}`,
        subtitle: `${t("bannerTile.moreInfo", defaultContent["bannerTile"]["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.QUALIFIED, QUALIFICATION_STATUS.TRACKING],
        title: `${t("bannerTile.bfi.greatJob", defaultContent["bannerTile"]["bfi"]["greatJob"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
        title: `${t("bannerTile.bfi.manual", defaultContent["bannerTile"]["bfi"]["manual"])}`,
        subtitle: `${t("bannerTile.moreInfo", defaultContent["bannerTile"]["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.HELD],
        title: `${t("bannerTile.onHold", defaultContent["bannerTile"]["onHold"])}`,
        subtitle: `${t("bannerTile.moreInfo", defaultContent["bannerTile"]["moreInfo"])}`,
      },
    ];

    const statusToHideIncentive = [
      QUALIFICATION_STATUS.DENIED,
      QUALIFICATION_STATUS.NOT_ELIGIBLE,
      QUALIFICATION_STATUS.HELD,
    ];

    if (show === false) return <></>;

    return (
      <div className="BFIBannerTile">
        <LeafIncentiveBannerTile
          bannerMessages={bannerMessages}
          incentive={{
            title: `${t("bfiLabel", defaultContent["bfiLabel"])}`,
            status: status,
            amount: consistencyPayment.totalPayment.amountCurrency, // The API will sum the base bonus and consistency payment to calculate the total. If the consistency payment is unavailable, the total will still reflect the base bonus amount.
            statusToHideIncentive,
          }}
          learnMoreLink={learnMoreLink}
          isLoading={isFetching}
          isCompactView={isCompactView}
          learnMoreTealiumFunction={learnMoreOnClick}
        />
      </div>
    );
  }
  return <LeafTile isLoading={isFetching} />;
};

export default BFIBannerTile;
