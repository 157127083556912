import { useEffect, useState } from "react";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import {
  PerformanceBonusResponse,
  PggOverviewResponse,
} from "../../../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI.types";
import {
  useGetPggOverviewQuery,
  useGetPggPerformanceBonusQuery,
} from "../../../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";
import { useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";
import { MULTIPLIER_DATA_TYPE } from "../../../common/enums/requirements";

export const defaultPggOverviewData = (): PggOverviewResponse => {
  return {
    IncomeData: {
      PerformanceBonusLevelPercent: "",
      PotentialPYEarnings: "",
      IncentiveCap: "",
      Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
    },
    MonthlyRequirements: {
      BadgeRequirements: [],
      MonthlyRubyRequirement: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
      RequirementMetStatuses: [],
    },
    AnnualRequirements: {
      VolumeRequirements: [],
      PercentRequirements: [],
      BadgeRequirements: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
      RequirementMetStatuses: [],
    },
    IncentiveData: {
      MultiplierData: [
        {
          Type: MULTIPLIER_DATA_TYPE.INCENTIVE,
          Base: "",
          Multiplier: "",
          Total: "",
        },
      ],
      TrackingQ: "",
      PotentialMultiplier: "",
      PreviousPYFQ: "",
      CurrentYTDFQ: "",
      ShowPreviousViewDetails: false,
      ShowFootnoteContent: false,
    },
    MultiplierMatrix: {
      ColumnHeaders: [],
      Rows: [],
      Legend: "",
      SubtextContent: "",
    },
    CallResults: [],
    Error: [],
  };
};

export const FetchAnnualPggOverviewData = (period: string, isComingSoon: boolean) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetPggOverviewQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      isoCountryCode: user.isoCountryCode,
      isoCurrencyCode: shellConfig.isoCurrencyCode,
      locale: storedLocale(),
    },
    {
      skip: isComingSoon,
    },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [annualPggOverviewData, setAnnualPggOverviewData] = useState<PggOverviewResponse>(defaultPggOverviewData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setAnnualPggOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, annualPggOverviewData, errorStatus, refetch };
};

export const defaultPggHistoryData = (): PerformanceBonusResponse => {
  return {
    CallResults: [],
    History: [
      {
        Year: "",
        Periods: [],
      },
    ],
    Error: [],
  };
};

export const FetchPggPerformanceBonusData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetPggPerformanceBonusQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [pggPerformanceBonusData, setPggPerformanceBonusData] = useState<PerformanceBonusResponse>(
    defaultPggHistoryData(),
  );

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setPggPerformanceBonusData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, pggPerformanceBonusData, errorStatus, refetch };
};
