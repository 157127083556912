import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../content/frontlineGrowth";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { TrackProgressTile } from "../../../common/components/TrackProgressTile/TrackProgressTile";

type Props = {
  totalRequirements: number;
  totalRequirementsMet: number;
  id?: string;
  isLoading: boolean;
  requirementMetStatuses: boolean[];
};

export default function TrackingProgressTile({
  isLoading,
  totalRequirements,
  totalRequirementsMet,
  id,
  requirementMetStatuses,
}: Props) {
  const { t } = useTranslation(["frontlineGrowth"]);
  const title = t("progressTile.title", defaultContent.progressTile.title).toString();
  const subtext = (
    <Trans>
      {t("progressTile.requirementLabel", defaultContent.progressTile["requirementLabel"], {
        current: totalRequirementsMet,
        needed: totalRequirements,
      })}
      <span
        className={totalRequirements === totalRequirementsMet ? "Tracking__bold Tracking__met" : "Tracking__bold"}
      ></span>
    </Trans>
  );

  const circlesSection = () => {
    return (
      <div className="Tracking__icons">
        {requirementMetStatuses &&
          requirementMetStatuses.map((isReqMet, index) => (
            <div
              key={index}
              tabIndex={0}
              role="img"
              aria-label={isReqMet ? `FGG Requirement ${index + 1} Met` : `Tracking FGG Requirement ${index + 1}`}
            >
              <IconCheckCircle key={index} id={index.toString()} color={isReqMet ? "green" : "gray"} size="xlg" />
            </div>
          ))}
      </div>
    );
  };

  return (
    <TrackProgressTile
      isLoading={isLoading}
      id={id}
      title={title}
      circlesSection={circlesSection()}
      subtext={subtext}
    />
  );
}
