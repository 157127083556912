import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { IncentiveCalculation } from "../../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconEquals } from "../../../../../common/icons/IconEquals/IconEquals";
import { IconMultiply } from "../../../../../common/icons/IconMultiply/IconMultiply";
import { PggGcrOverviewResponse } from "../../../../../services/GroupGrowth/PersonalGroupGrowthGCR/personalGroupGrowthGCRAPI.types";
import defaultContent from "../../../../../content/annualGcrPGG";
import { getPerformanceYearShort } from "../../../../../common/util/period";
import { MatrixFQModal } from "../../../../../common/components/MatrixFQModal/MatrixFQModal";
import { setModalBtnFocus } from "../../../AnnualPGG/MultiplierDetails/MultiplierDetailsUtil";
import { useAppSelector } from "../../../../../store";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { IconTable } from "../../../../../common/icons/IconTable/IconTable";
import { LeafIconButton } from "../../../../../common/leaf/LeafIconButton/LeafIconButton";

type Props = {
  isLoading: boolean;
  selectedPeriod: string;
  prevOpenRef?: MutableRefObject<boolean>;
  overview: PggGcrOverviewResponse;
  isExpanded?: boolean;
};

export default function GCRPGGIncentiveCalculation({
  isLoading,
  selectedPeriod,
  prevOpenRef,
  overview,
  isExpanded,
}: Props) {
  const { t } = useTranslation(["annualGcrPGG"]);
  const { IncentiveData, MultiplierMatrix } = overview;
  const viewMatrixButton = useRef<HTMLButtonElement>(null);
  const [openMatrixModal, setOpenMatrixModal] = useState<boolean>(false);
  const PY = getPerformanceYearShort(selectedPeriod);
  const { user } = useAppSelector((state) => state.boot);

  const showMatrixModal = () => {
    setOpenMatrixModal(!openMatrixModal);
    setModalBtnFocus(viewMatrixButton, prevOpenRef!, openMatrixModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "personal_group_growth_gcr",
      "personal_group_growth_gcr",
      `incentive_multiplier_matrix_model_open/close`,
      "multiplier_matrix_modal",
    );
  };

  const isHKMarket = user.isoCountryCode === "HK" ? true : false;

  return (
    <>
      <IncentiveCalculation
        isLoading={isLoading}
        base={{
          title: t(`incentiveCalculation.paymentBase`, String(defaultContent.incentiveCalculation.paymentBase)),
          amount: IncentiveData.MultiplierData[0].Base,
        }}
        multiplier={{
          title: t(`incentiveCalculation.multiplier`, String(defaultContent.incentiveCalculation.multiplier)),
          amount: IncentiveData.MultiplierData[0].Multiplier,
          icon: (
            <LeafIconButton
              aria-label="GCRPGG Incentive Calculation MultiplTable Icon Button"
              Icon={<IconTable />}
              HoverIcon={<IconTable color="blue" />}
            />
          ),
          symbolIcon: <IconMultiply />,
          ref: viewMatrixButton,
          callbackFn: showMatrixModal,
          type: IncentiveData.MultiplierData[0].Type,
        }}
        total={{
          title: t(`incentiveCalculation.potential`, String(defaultContent.incentiveCalculation.potential), {
            perfYear: PY,
          }),
          amount: IncentiveData.MultiplierData[0].Total,
          symbolIcon: <IconEquals />,
        }}
        isExpanded={isExpanded}
      />
      <MatrixFQModal
        displayModal={openMatrixModal}
        toggleModal={showMatrixModal}
        incentive="annualPGGGCR"
        matrixFQData={{
          matrixFQs: "",
          potentialMultiplier: IncentiveData.MultiplierData[0].Multiplier,
          performanceYear: "",
          multiplierMatrixData: MultiplierMatrix,
        }}
        joinIdenticalRowsVertically={isHKMarket}
      />
    </>
  );
}
