import { useTranslation } from "react-i18next";
import { IconLightBulb } from "../../../icons/IconLightBulb/IconLightBulb";
import "../LeafIdleCustomer/LeafIdleCustomer.scss";
import defaultContent from "../../../../content/profile";
import LeafABOName from "../LeafABOName/LeafABOName";

type Props = {
  customerDetail?: {
    name: string;
    localName: string;
    privacyFlag: boolean;
    isoLanguageCode: string;
    businessNatureCode: number;
    lastOrderDate: string;
  };
  dayCount: number;
};

const LeafIdleCustomer = (props: Props) => {
  const { t } = useTranslation(["profile"]);
  const { dayCount, customerDetail } = props;

  if (customerDetail) {
    const { name, localName, privacyFlag, isoLanguageCode, businessNatureCode, lastOrderDate } = customerDetail;

    const orderDate = new Date(lastOrderDate).getTime();
    const timestamp = new Date().getTime();

    const invalidDate = isNaN(orderDate) === true;
    // if difference is more then dayCount, show idleCustomer
    const noRecentOrder = timestamp - orderDate > dayCount * 24 * 60 * 60 * 1000;

    return (
      <>
        {noRecentOrder || invalidDate ? (
          <div className="leaf-idle-customer__container">
            <div className="leaf-idle-customer__container--header">
              <IconLightBulb />
              <div>
                {`${t(
                  "customerProfile.overviewTab.idleCustomer",
                  defaultContent["customerProfile"]["overviewTab"]["idleCustomer"],
                )}`}{" "}
                {businessNatureCode === 3 &&
                  `${t(
                    "customerProfile.overviewTab.member",
                    defaultContent["customerProfile"]["overviewTab"]["member"],
                  )}`}
                {businessNatureCode === 4 &&
                  `${t(
                    "customerProfile.overviewTab.customer",
                    defaultContent["customerProfile"]["overviewTab"]["customer"],
                  )}`}
              </div>
            </div>
            <div className="leaf-idle-customer__container--description">
              <LeafABOName name={name} localName={localName} privacyFlag={privacyFlag} languageCode={isoLanguageCode} />
              <span className="leaf-idle-customer__container--text">
                {t(
                  "customerProfile.overviewTab.description",
                  defaultContent["customerProfile"]["overviewTab"]["description"],
                  {
                    DAY_COUNT: dayCount,
                  },
                )}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  return <></>;
};

export default LeafIdleCustomer;
