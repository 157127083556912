import { useTranslation } from "react-i18next";
import { LegsPercentageTile } from "../../../../../common/components/RequirementTiles/LegsPercentageTile/LegsPercentageTile";
import defaultContent from "../../../../../content/bronzeIncentives";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import { viewDownlineInfoButtonOnClick } from "../../../../../common/tealium/BronzeIncentive/ViewDownlineInfoButtonTrack";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  targetVCSPV: number;
  percent: number;
  formatted: {
    targetVCSPV: string;
    percent: string;
  };
};

type Props = {
  isFetching: boolean;
  module: string;
} & Partial<Requirement>;

const BronzeRequirementVCSLegs = (props: Props) => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const {
    met = false,
    required = false,
    actual = 0,
    target = 0,
    short = 0,
    formatted: { targetVCSPV: formattedTargetVCSPV = "", percent: formattedPercent = "" } = {},
    module,
    isFetching,
  } = props;
  const viewDownlineButtonOnClick = () => {
    window.open(ROUTE_PATHS.LOS, "_blank");
    viewDownlineInfoButtonOnClick(module, "performance_bonus_percent_leg_with_vcs_pv");
  };

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <LegsPercentageTile
      isLoading={isFetching}
      circleCheckmarkId={"legs-percentage"}
      met={met}
      title={`${t("bronzeRequirements.vcsBonusLegsTitle", defaultContent["bronzeRequirements"]["vcsBonusLegsTitle"], {
        vcspv: formattedTargetVCSPV,
        percent: formattedPercent,
      })}`}
      actual={actual}
      target={target}
      short={short}
      viewDownlineButtonOnClick={viewDownlineButtonOnClick}
      viewDownlineButtonText={`${t(
        "bronzeRequirements.viewDownlineButton",
        defaultContent["bronzeRequirements"]["viewDownlineButton"],
      )}`}
    />
  );
};

export default BronzeRequirementVCSLegs;
